import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { CurrentState, Step } from "../../models";
import { Context } from "../../state/Store";
import { Dialog, GoBackButton } from "../../components";
import { UserIcon } from "./UserIcon";
import { MySelection } from "./MySelection";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../route/RouteConfig";
import { ReducerKeys } from "../../state";

export const Header = (props: { step: number }) => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [activeStep, setActiveStep] = useState(null as unknown as Step);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (state && state.steps) {
      setActiveStep(state.steps[props.step - 1]);
    }
  }, [state.steps, props.step]);

  const goToStep1 = () => {
    // clear state
    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 6,
    });
    history.push(RouteConstants.Steps);
  };

  return (
    <header className="header header-auto header-gray">
      <div className="container">
        <div className="header-control d-flex align-items-center">
          <GoBackButton />

          <div className="header-dots d-flex align-items-center">
            {state.steps &&
              state.steps.map((step: Step, index: number) => {
                return (
                  <span
                    key={index}
                    className={`${index + 1 <= props.step ? "active" : ""}`}
                  ></span>
                );
              })}
          </div>
          <UserIcon />
        </div>
        {activeStep && (
          <motion.div
            initial={MotionAnimations.fade.exit}
            animate={MotionAnimations.fade.enter}
            exit={MotionAnimations.fade.exit}
            className="page-info px-0"
          >
            <div className="page-info-step d-flex align-items-center">
              {activeStep.afbeelding && (
                <div className="page-info-step-count">
                  <img src={activeStep.afbeelding} alt="" />
                </div>
              )}
              <div className="w-100 d-flex align-items-center justify-content-space-between">
                <div>
                  <strong style={{ color: activeStep.kleur }}>
                    {activeStep.subtitel}
                  </strong>
                  <h1>{activeStep.title}</h1>
                </div>
                {(props.step === 5 || props.step === 6) && (
                  <button
                    onClick={goToStep1}
                    className="small-button btn bg-red text-white text-center"
                  >
                    New selection
                  </button>
                )}
              </div>
            </div>
            <p>
              {activeStep.intro}
              {props.step === 5 && (
                <a
                  style={{ marginLeft: "5px" }}
                  onClick={() => setIsDialogOpen(true)}
                >
                  <img src="/images/info.svg" alt="info" />
                </a>
              )}
            </p>
            {isDialogOpen && (
              <Dialog
                onClose={() => {
                  setIsDialogOpen(false);
                }}
              >
                {() => {
                  return <MySelection />;
                }}
              </Dialog>
            )}
          </motion.div>
        )}
      </div>
    </header>
  );
};
