import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CompanyLogo, GoBackButton } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { ResetPassword } from "../../models";
import { UserService } from "../../services";

export const RequestResetPassword = () => {
  const [formValid, setFormValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const [success, setSuccess] = useState(false);
  const errorRef = useRef(null);

  const initialValues = {
    email: "",
  };

  useEffect(() => {
    if (validationErrors?.length > 0 || success) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors, success]);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);
    setSuccess(false);

    if (values.email) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const requestReset = async (data: ResetPassword) => {
    try {
      await trackPromise(UserService.logout());
      await trackPromise(UserService.requestResetPassword(data));
      setSuccess(true);
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex py-20">
        <div className="container mt-auto">
          <div className="logo">
            <CompanyLogo />
          </div>
        </div>
      </header>

      <div className="container">
        <div className="mb-10 px-sm-15">
          <GoBackButton />
        </div>
      </div>

      <main className="py-25 px-sm-15 position-relative overflow-auto flex-grow d-flex bg-white">
        <div className="container">
          <div className="page-info mb-25">
            <h1>Request password reset</h1>
            <p>Please provide your username</p>
          </div>

          <Formik
            initialValues={initialValues}
            validate={(values) => formValidation(values, false)}
            onSubmit={(values, { setSubmitting }) => {
              const errors = formValidation(values, true);
              if (errors?.length === 0) {
                requestReset(values);
              }
            }}
            render={(formProps) => {
              return (
                <Form id="requestResetForm">
                  <div className="form-group">
                    <label>
                      E-MAIL<sup>*</sup>
                    </label>
                    <Field
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      placeholder="Your e-mail"
                    />
                  </div>
                  <div ref={errorRef}>
                    {validationErrors?.length > 0 && (
                      <div className="pt-20">
                        {validationErrors.map((err: string) => {
                          return (
                            <React.Fragment key={err}>
                              <div className="text-danger">{err}</div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    {success && (
                      <React.Fragment>
                        <div className="text-success pt-20">
                          If you provided a valid e-mail you will receive an
                          e-mail with further instructions.
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Form>
              );
            }}
          ></Formik>
        </div>
      </main>

      <footer className="footer px-sm-15 text-center bg-white">
        <div className="container">
          <button
            disabled={!formValid}
            form="requestResetForm"
            type="submit"
            className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
          >
            Request
          </button>
        </div>
      </footer>
    </motion.div>
  );
};
