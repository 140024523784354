import { Link } from "react-router-dom";
import { RouteConstants } from "../../../route/RouteConfig";
import { UserIcon } from "../../../components";
import { motion } from "framer-motion";
import { MotionAnimations } from "../../../animations/MotionAnimations";

export const ThankYou = () => {
  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto pb-2">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <UserIcon />
          </div>
        </div>
      </header>

      <main className="px-sm-15 text-center thank-you position-relative bg-white overflow-auto flex-grow">
        <div className="container">
          <img src="images/logo.svg" className="thank-you-logo" alt="logo" />
          <h6 className="group-title">THANK YOU</h6>
          <h1>
            Your request <br />
            has been sent.
          </h1>
          <p></p>
          <div className="d-flex justify-content-center">
            <Link
              style={{ margin: "0 10px" }}
              to={RouteConstants.Start}
              className="btn bg-red btn-login mb-18 text-white btn-lg text-center"
            >
              Home
            </Link>
            <Link
              style={{ margin: "0 10px" }}
              to={RouteConstants.About}
              className="btn bg-red btn-login mb-18 text-white btn-lg text-center"
            >
              New selection
            </Link>
          </div>
        </div>
      </main>
    </motion.div>
  );
};
