import { motion } from "framer-motion";
import React from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { ProcedureData, User } from "../../models";
import { ProductService } from "../../services";
import { GoBackButton } from "../shared/GoBackButton";
import { UserIcon } from "../shared/UserIcon";
import moment from "moment";
import { RouteConstants } from "../../route/RouteConfig";
import { Link } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { NewSelectionButton } from "..";

export const Requests = () => {
  const [procedures, setProcedures] = useState(
    null as unknown as ProcedureData[]
  );
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const [userInfo, setUserInfo] = useState(null as unknown as User);

  // onload
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const user = await AuthService.GetCurrentUser();
      setUserInfo(user);

      const result = await trackPromise(ProductService.getProcedures());
      setProcedures(result);
    } catch (e: any) {
      setValidationErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton />
            <UserIcon />
          </div>

          <div className="page-info px-0">
            <div className="page-info-step mb-8 d-flex align-items-center">
              <h1>Requests overview</h1>
            </div>

            {userInfo && userInfo.isAdmin && (
              <p>Requested product selections</p>
            )}
            {userInfo && !userInfo.isAdmin && (
              <p>Product selections you have requested</p>
            )}
          </div>
        </div>
      </header>

      <main className="py-md-25 py-20 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          <div className="accordion-checkbox-list mb-0">
            {procedures &&
              procedures.map((procedure: ProcedureData) => {
                return (
                  <div key={procedure.key} className="accordion-checkbox pb-15">
                    <div className="accordion-checkbox-header ">
                      <h6>
                        {procedure.products.length}{" "}
                        {procedure.products.length === 1
                          ? "Product"
                          : "Products"}
                      </h6>
                      <h5 className="fw-800">
                        {procedure.products.map((x) => x.product).join(", ")}
                      </h5>
                    </div>

                    <div className="accordion-checkbox-result mb-10 accordion-result">
                      <div className="d-flex mb-8 align-items-center">
                        <div className="accordion-result-title">
                          DATE & TIME
                        </div>
                        <div>
                          {`${moment(procedure.datum).format(
                            "DD MMMM yyyy"
                          )}, ${moment(procedure.uur).format("HH:mm")}`}
                        </div>
                      </div>
                      <div className="d-flex mb-8 align-items-center">
                        <div className="accordion-result-title">USER</div>
                        <div>
                          {`${procedure.user?.firstName} ${procedure.user?.lastName}`}
                        </div>
                      </div>
                      <div className="d-flex mb-8 align-items-center">
                        <div className="accordion-result-title">COMPANY</div>
                        <div>{procedure.user?.companyName}</div>
                      </div>
                    </div>

                    <Link
                      className="btn-link text-blue"
                      to={RouteConstants.RequestDetail.replace(
                        ":id",
                        `${procedure.key}`
                      )}
                    >
                      {" "}
                      More info
                    </Link>
                  </div>
                );
              })}

            {procedures && procedures.length === 0 && (
              <p className="mb-25">No requests found.</p>
            )}

            {validationErrors?.length > 0 &&
              validationErrors.map((err: string) => {
                return (
                  <React.Fragment key={err}>
                    <br />
                    <div className="text-danger">{err}</div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </main>
      {procedures && procedures.length === 0 && (
        <footer className="footer px-sm-15 text-center bg-white">
          <div className="container">
            <NewSelectionButton />
          </div>
        </footer>
      )}
    </motion.div>
  );
};
