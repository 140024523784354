import { motion } from "framer-motion";
import { GoBackButton, UserIcon } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductService } from "../../services";
import React from "react";
import {
  ConstructionType,
  CurrentState,
  MaterialItem,
  ProcedureData,
  RequestOption,
} from "../../models";
import moment from "moment";
import { Context } from "../../state";

export const RequestDetail = () => {
  const { id } = useParams() as any;
  const [errors, setErrors] = useState([] as string[]);
  const [procedure, setProcedure] = useState(null as unknown as ProcedureData);
  const [state] = useContext(Context) as [CurrentState];

  // onload
  useEffect(() => {
    fetchData();
  }, [state.steps]);

  const fetchData = async () => {
    try {
      const procedure = await trackPromise(ProductService.getProcedure(id));
      setProcedure(procedure);
    } catch (e: any) {
      setErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton />
            <UserIcon />
          </div>

          {procedure && procedure.products && (
            <div className="page-info px-0">
              <div className="page-info-step mb-8 d-flex align-items-center">
                <h1>{procedure.products.map((x) => x.product).join(", ")}</h1>
              </div>
              <p>
                {`${moment(procedure.datum).format("DD MMMM yyyy")}, ${moment(
                  procedure.uur
                ).format("HH:mm")}`}
              </p>
            </div>
          )}
        </div>
      </header>

      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {procedure && procedure.products && state.steps.length > 0 && (
            <div className="accordion-checkbox-list mb-0">
              <div className="accordion-checkbox">
                <div className="accordion-checkbox-header d-flex flex-column">
                  <h5>Your selection</h5>
                </div>

                <div className="accordion-checkbox-result accordion-product">
                  <div className="request-detail-container">
                    <div
                      className="accordion-product-title"
                      style={{ color: state.steps[0].kleur }}
                    >
                      {state.steps[0].title}
                    </div>
                    <div>{procedure.laminationMaterial?.name}</div>
                  </div>
                  <div className="request-detail-container">
                    <div
                      className="accordion-product-title"
                      style={{ color: state.steps[1].kleur }}
                    >
                      {state.steps[1].title}
                    </div>
                    <div>{procedure.surfaceEnergy?.name}</div>
                  </div>
                  <div className="request-detail-container">
                    <div
                      className="accordion-product-title"
                      style={{ color: state.steps[2].kleur }}
                    >
                      {state.steps[2].title}
                    </div>
                    <div>
                      {procedure.extraRequirements?.map(
                        (value: MaterialItem, index: number) => {
                          return (
                            <div
                              className={
                                index != procedure.extraRequirements.length - 1
                                  ? "pb-15"
                                  : ""
                              }
                              key={value.recordId}
                            >
                              {value.name}
                              {value.isTemperatureRecord && (
                                <span>: {procedure.maxServiceTemperature}</span>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="request-detail-container">
                    <div
                      className="accordion-product-title"
                      style={{ color: state.steps[3].kleur }}
                    >
                      {state.steps[3].title}
                    </div>

                    <div>
                      {procedure.constructionType?.map(
                        (value: ConstructionType, index: number) => {
                          return (
                            <div
                              className={
                                index != procedure.constructionType.length - 1
                                  ? "pb-15"
                                  : ""
                              }
                              key={value.recordId}
                            >
                              {value.title}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="request-detail-container">
                    <div className="accordion-product-title">Request</div>

                    <div>
                      {procedure.requestOptions?.map(
                        (value: RequestOption, index: number) => {
                          return (
                            <div
                              className={
                                index != procedure.requestOptions.length - 1
                                  ? "pb-15"
                                  : ""
                              }
                              key={value.recordId}
                            >
                              {value.name}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="accordion-checkbox-header d-flex flex-column">
                    <div className="accordion-product-title">Remark</div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${procedure.remark}`,
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className="accordion-checkbox-header d-flex flex-column">
                    <h5>
                      {procedure.products.length}{" "}
                      {procedure.products.length === 1 ? "Product" : "Products"}
                    </h5>
                  </div>
                  {procedure.products?.map((prod) => {
                    return (
                      <div className="request-detail-container accordion-product">
                        <div className="accordion-product-title">
                          {prod.product}
                        </div>
                        <div>{prod.linerOptions}</div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="accordion-checkbox">
                <div className="accordion-checkbox-header d-flex flex-column">
                  <h3>User info</h3>
                  {procedure.user && (
                    <h5 className="fw-800 mb-8">{`${procedure.user.firstName} ${procedure.user.lastName}`}</h5>
                  )}

                  <div className="text-dark-gray">
                    <div>{procedure.user?.email}</div>
                  </div>
                </div>

                <div className="accordion-checkbox-header d-flex flex-column">
                  <h3>Company info</h3>
                  <h5 className="fw-800 mb-8">{procedure.user?.companyName}</h5>

                  <div className="text-dark-gray">
                    <div>{procedure.user?.companyRegion?.name}</div>
                  </div>
                </div>

                {procedure.streetAddress1 && (
                  <div className="accordion-checkbox-header d-flex flex-column">
                    <h3>Shipping details</h3>
                    <h5 className="mb-8 text-dark-gray">
                      <div>Phone</div>
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "15px",
                          marginTop: "8px",
                        }}
                      >
                        {procedure.phone}
                      </p>
                      <div>Address:</div>
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "15px",
                          marginTop: "8px",
                        }}
                      >
                        <div>{procedure.streetAddress1}</div>
                        <div>{procedure.streetAddress2}</div>
                        <div>
                          {procedure.city} - {procedure.state}
                        </div>
                        <div>
                          {procedure.zip} - {procedure.country}
                        </div>
                      </p>
                    </h5>

                    <div className="text-dark-gray">
                      <div>{procedure.user?.companyRegion?.name}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {errors &&
            errors.map((err: string) => {
              return (
                <React.Fragment key={err}>
                  <br />
                  <div className="text-danger">{err}</div>
                </React.Fragment>
              );
            })}
        </div>
      </main>
    </motion.div>
  );
};
