import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import { Footer, Header } from "../../..";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import {
  AdhesiveCategory,
  CurrentState,
  MaterialItem,
} from "../../../../models";
import { RouteConstants } from "../../../../route/RouteConfig";
import { ProductService } from "../../../../services";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";

export const Step3 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  // onload
  useEffect(() => {
    if (state.applicationRequirements.length === 0) {
      fetchData();
    }

    if (state.selectedMaxServiceTemperature != null) {
      setIsOpen(true);
    }
  }, []);

  const fetchData = async () => {
    const requirements = await trackPromise(
      ProductService.getApplicationRequirements(
        state.selectedSurfaceEnergyMaterial?.categories as AdhesiveCategory[]
      )
    );

    dispatch({
      type: ReducerKeys.SET_APPLICATION_REQUIREMENTS,
      payload: requirements,
    });
  };

  const selectionChanged = (items: MaterialItem[]) => {
    let shouldForward = false;
    if (items && items.length > 0) {
      const nonRequired = items.find((x) => x.recordId == 0);

      if (nonRequired) {
        if (items[items.length - 1].recordId == nonRequired.recordId) {
          // nonrequired is last selected --> clear other values
          items = [nonRequired];

          // clear max temp
          dispatch({
            type: ReducerKeys.SET_SELECTED_MAX_TEMPERATURE,
            payload: null,
          });

          shouldForward = true;
        } else {
          items = [...items.filter((x) => x.recordId != nonRequired.recordId)];
        }
      }
    }

    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_APPLICATION_REQUIREMENTS,
      payload: items,
    });

    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 3,
    });

    if (shouldForward) {
      history.push(RouteConstants.Step4);
    }
  };

  const serviceTemperatureSelectionChanged = (items: MaterialItem[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SET_SELECTED_MAX_TEMPERATURE,
      payload: items?.length > 0 ? items[0] : items,
    });

    const seriveTemp = state.applicationRequirements.filter(
      (x) => x.isTemperatureRecord
    )[0];

    const exist = state.selectedApplicationRequirements?.find(
      (x) => x.recordId == seriveTemp.recordId
    );

    if (!exist) {
      state.selectedApplicationRequirements.push(seriveTemp);
    } else {
      const index = state.selectedApplicationRequirements.indexOf(seriveTemp);
      if (index > -1) {
        state.selectedApplicationRequirements.splice(index, 1);

        if (
          state.selectedMaxServiceTemperature?.recordId == items[0].recordId
        ) {
          dispatch({
            type: ReducerKeys.SET_SELECTED_MAX_TEMPERATURE,
            payload: null,
          });
        } else {
          dispatch({
            type: ReducerKeys.SET_SELECTED_MAX_TEMPERATURE,
            payload: items[0],
          });
        }
      }
    }

    selectionChanged(state.selectedApplicationRequirements);
  };

  return (
    <React.Fragment>
      <Header step={3} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {state.applicationRequirements && (
            <motion.div
              initial={MotionAnimations.slideLeft.initial}
              animate={MotionAnimations.slideLeft.animate}
              exit={MotionAnimations.slideLeft.exit}
              transition={MotionAnimations.slideLeft.transition}
            >
              <React.Fragment>
                <div className="accordion-subitem">
                  <AccordionSubItem
                    multiSelect={true}
                    item={{
                      name: "None required",
                      recordId: 0,
                      disabled: false,
                    }}
                    selectedItems={state.selectedApplicationRequirements}
                    selectionChanged={selectionChanged}
                  ></AccordionSubItem>
                </div>

                {state.applicationRequirements.map(
                  (item: MaterialItem, index: number) => {
                    if (item.isTemperatureRecord) {
                      item.isParent = true;
                    }
                    return (
                      <React.Fragment key={index}>
                        <AccordionSubItem
                          key={index}
                          multiSelect={true}
                          item={item}
                          selectedItems={state.selectedApplicationRequirements}
                          selectionChanged={selectionChanged}
                          parentChanged={() => setIsOpen(!isOpen)}
                        ></AccordionSubItem>

                        {(() => {
                          if (!isOpen) {
                            return;
                          }

                          if (!item.isTemperatureRecord) {
                            return;
                          }

                          const subItems = item.categories
                            .filter((x) => x.maxServiceTemperature != "")
                            .sort((a, b) =>
                              a.maxServiceTemperatureNumber <
                              b.maxServiceTemperatureNumber
                                ? -1
                                : a.maxServiceTemperatureNumber >
                                  b.maxServiceTemperatureNumber
                                ? 1
                                : 0
                            )
                            .map((x) => {
                              return {
                                name: x.maxServiceTemperature,
                                disabled: x.disabled,
                                recordId: x.recordId,
                                maxServiceTemperatureNumber:
                                  x.maxServiceTemperatureNumber,
                              };
                            });

                          const lowestValidTemp = subItems.find(
                            (x) => x.disabled == false
                          )?.maxServiceTemperatureNumber;
                          const unique = Array.from(
                            new Set(subItems.map((item: any) => item.name))
                          );

                          return unique?.map((uniqueItem, index) => {
                            const item = subItems.find(
                              (x) => x.name == uniqueItem
                            );

                            if (item != null && lowestValidTemp != null) {
                              if (
                                item.maxServiceTemperatureNumber <
                                lowestValidTemp
                              ) {
                                item.disabled = true;
                              } else {
                                item.disabled = false;
                              }
                            }

                            return (
                              <AccordionSubItem
                                key={index}
                                multiSelect={false}
                                item={item}
                                selectedItems={
                                  state.selectedMaxServiceTemperature
                                    ? [state.selectedMaxServiceTemperature]
                                    : []
                                }
                                selectionChanged={
                                  serviceTemperatureSelectionChanged
                                }
                                canDeselect={true}
                                style={{ marginLeft: "40px" }}
                              ></AccordionSubItem>
                            );
                          });
                        })()}
                      </React.Fragment>
                    );
                  }
                )}
              </React.Fragment>
            </motion.div>
          )}
        </div>
      </main>
      <Footer step={4} showNext={true} />
    </React.Fragment>
  );
};
