import React, { createContext, useReducer } from "react";
import Reducer, { initialState } from "./Reducer";

export const Store: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext({});
