import { CurrentState } from "./currentState";

export class Procedure {
  key: string | undefined;
  datum?: Date;
  laminationMaterial!: number | undefined;
  surfaceEnergy!: number | undefined;
  extraRequirements!: number[];
  maxServiceTemperature!: string | undefined;
  userId: number | undefined;
  type!: number[];
  constructionType!: number[];
  products!: number[];
  requestOptions!: number[];
  remark?: string;
  completed!: boolean;
  streetAddress1!: string;
  streetAddress2!: string;
  city!: string;
  state!: string;
  zip!: string;
  country!: string;
  phone!: string;

  constructor(data?: Procedure) {
    if (data) {
      Object.assign(this, data);
    }
  }

  fromState(state: CurrentState): Procedure {
    this.key = state.procedureId;
    this.laminationMaterial = state.selectedLaminationMaterial?.recordId;
    this.surfaceEnergy = state.selectedSurfaceEnergyMaterial?.recordId;
    this.extraRequirements = state.selectedApplicationRequirements.map(
      (a) => a.recordId
    );
    this.maxServiceTemperature = state.selectedMaxServiceTemperature?.name;
    this.constructionType = state.selectedConstructionTypes.map(
      (a) => a.recordId
    );
    this.products = state.selectedAvailableProducts.map((a) => a.recordId);
    this.requestOptions = state.selectedRequestOptions.map((a) => a.recordId);
    this.remark = state.requestDescription;
    this.streetAddress1 = state.shippingDetail.streetAddress1;
    this.streetAddress2 = state.shippingDetail.streetAddress2;
    this.city = state.shippingDetail.city;
    this.state = state.shippingDetail.state;
    this.zip = state.shippingDetail.zip;
    this.country = state.shippingDetail.country;
    this.phone = state.shippingDetail.phone;

    return this;
  }
}
