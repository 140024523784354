import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Dialog, Footer, Header } from "../../..";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import {
  AdhesiveCategory,
  ConstructionType,
  CurrentState,
} from "../../../../models";
import { ProductService } from "../../../../services";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";
import { ConstructionTypeBody } from "../../../shared/ConstructionTypeBody";

export const Step4 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [currentConstructionType, setCurrentConstructionType] = useState(
    null as unknown as ConstructionType
  );

  // onload
  useEffect(() => {
    if (state.linerTypes.length === 0 || state.constructionTypes.length === 0) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    let categories = [] as AdhesiveCategory[];
    const nonRequired = state.selectedApplicationRequirements?.find(
      (x) => x.recordId == 0
    );

    if (
      state.selectedApplicationRequirements.length > 0 &&
      nonRequired == null
    ) {
      categories = state.selectedApplicationRequirements
        .map((val) => val.categories)
        .reduce(function (prev, next) {
          return prev.concat(next);
        });
    } else {
      // if no application requirements are selected just pass the previous filter
      categories = state.selectedSurfaceEnergyMaterial
        ?.categories as AdhesiveCategory[];
    }

    const constructionTypes = await trackPromise(
      ProductService.getConstructionTypes(
        categories,
        state.selectedMaxServiceTemperature
      )
    );

    dispatch({
      type: ReducerKeys.SET_CONSTRUCTIONTYPES,
      payload: constructionTypes,
    });
  };

  const constructionTypeSelectionChanged = (items: ConstructionType[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_CONSTRUCTIONTYPES,
      payload: items,
    });

    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 4,
    });
  };

  const showConstructionDetail = (item: ConstructionType) => {
    setCurrentConstructionType(item);
  };

  const parentChanged = (item: ConstructionType) => {
    const currentItem = state.selectedParentConstructionType;
    dispatch({
      type: ReducerKeys.SET_SELECTED_PARENT_CONSTRUCTIONTYPE,
      payload: currentItem?.recordId == item?.recordId ? undefined : item,
    });
  };

  return (
    <React.Fragment>
      <Header step={4} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {state.constructionTypes &&
            state.constructionTypes
              ?.filter((x) => x.parent == null)
              .map((item: ConstructionType, index: number) => {
                return (
                  <motion.div
                    key={index}
                    className="accordion-subitem"
                    initial={MotionAnimations.slideLeft.initial}
                    animate={MotionAnimations.slideLeft.animate}
                    exit={MotionAnimations.slideLeft.exit}
                    transition={MotionAnimations.slideLeft.transition}
                  >
                    <React.Fragment>
                      <AccordionSubItem
                        multiSelect={false}
                        item={item}
                        selectedItems={state.selectedConstructionTypes}
                        selectionChanged={constructionTypeSelectionChanged}
                        showDetail={showConstructionDetail}
                        parentChanged={parentChanged}
                      ></AccordionSubItem>

                      {(() => {
                        const subItems = state.constructionTypes?.filter(
                          (x) =>
                            x.parent != null &&
                            x.parent.recordId == item.recordId &&
                            x.parent.recordId ==
                              state.selectedParentConstructionType?.recordId
                        );

                        return subItems?.map((subItem, index) => (
                          <AccordionSubItem
                            key={index}
                            multiSelect={false}
                            item={subItem}
                            selectedItems={state.selectedConstructionTypes}
                            selectionChanged={constructionTypeSelectionChanged}
                            style={{ marginLeft: "40px" }}
                          ></AccordionSubItem>
                        ));
                      })()}
                    </React.Fragment>
                  </motion.div>
                );
              })}
        </div>
      </main>
      {currentConstructionType && (
        <Dialog
          onClose={() =>
            setCurrentConstructionType(null as unknown as ConstructionType)
          }
        >
          {() => {
            return (
              <ConstructionTypeBody
                item={currentConstructionType}
              ></ConstructionTypeBody>
            );
          }}
        </Dialog>
      )}
      <Footer step={5} showNext={state.selectedConstructionTypes.length > 0} />
    </React.Fragment>
  );
};
