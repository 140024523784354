import { CurrentState } from "../models";
import { ShippingDetail } from "../models/shippingDetail";
import { ReducerKeys } from "./ReducerKeys";

export const initialState = {
  steps: [],
  currentStep: 1,
  groupedLaminationMaterials: [],
  groupedSurfaceEnergyMaterials: [],
  applicationRequirements: [],
  requestOptions: [],
  selectedApplicationRequirements: [],
  linerTypes: [],
  constructionTypes: [],
  selectedConstructionTypes: [],
  availableProducts: [],
  selectedAvailableProducts: [],
  selectedRequestOptions: [],
  procedureId: undefined,
  requestDescription: undefined,
  shippingDetail: new ShippingDetail(),
} as CurrentState;

const Reducer = (state: CurrentState, action: any) => {
  switch (action.type) {
    case ReducerKeys.SET_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case ReducerKeys.SET_INFORMATION_TEXT:
      return {
        ...state,
        informationText: action.payload,
      };
    case ReducerKeys.SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case ReducerKeys.SET_LAMINATION_MATERIALS:
      return {
        ...state,
        groupedLaminationMaterials: action.payload,
      };
    case ReducerKeys.SELECTED_LAMINATION_MATERIAL:
      return {
        ...state,
        selectedLaminationMaterial: action.payload,
      };
    case ReducerKeys.SET_SURFACE_ENERGY_MATERIALS:
      return {
        ...state,
        groupedSurfaceEnergyMaterials: action.payload,
      };
    case ReducerKeys.SELECTED_SURFACE_ENERGY_MATERIAL:
      return {
        ...state,
        selectedSurfaceEnergyMaterial: action.payload,
      };
    case ReducerKeys.SET_APPLICATION_REQUIREMENTS:
      return {
        ...state,
        applicationRequirements: action.payload,
      };
    case ReducerKeys.SELECTED_APPLICATION_REQUIREMENTS:
      return {
        ...state,
        selectedApplicationRequirements: action.payload,
      };
    case ReducerKeys.SET_LINERTYPES:
      return {
        ...state,
        linerTypes: action.payload,
      };
    case ReducerKeys.SET_CONSTRUCTIONTYPES:
      return {
        ...state,
        constructionTypes: action.payload,
      };
    case ReducerKeys.SELECTED_CONSTRUCTIONTYPES:
      return {
        ...state,
        selectedConstructionTypes: action.payload,
      };
    case ReducerKeys.SET_AVAILABLE_PRODUCTS:
      return {
        ...state,
        availableProducts: action.payload,
      };
    case ReducerKeys.SELECTED_AVAILABLE_PRODUCTS:
      return {
        ...state,
        selectedAvailableProducts: action.payload,
      };
    case ReducerKeys.SET_REQUEST_OPTIONS:
      return {
        ...state,
        requestOptions: action.payload,
      };
    case ReducerKeys.SELECTED_REQUEST_OPTIONS:
      return {
        ...state,
        selectedRequestOptions: action.payload,
      };
    case ReducerKeys.SET_REQUEST_DESCRIPTION:
      return {
        ...state,
        requestDescription: action.payload,
      };
    case ReducerKeys.SET_SELECTED_PARENT_CONSTRUCTIONTYPE:
      return {
        ...state,
        selectedParentConstructionType: action.payload,
      };
    case ReducerKeys.SET_SELECTED_MAX_TEMPERATURE:
      return {
        ...state,
        selectedMaxServiceTemperature: action.payload,
      };
    case ReducerKeys.CLEAR_STEPS:
      return clearState(state, action.payload);
    case ReducerKeys.SET_PROCEDURE_ID:
      return {
        ...state,
        procedureId: action.payload,
      };
    case ReducerKeys.SET_SHIPPINGDETAILS:
      return {
        ...state,
        shippingDetail: action.payload,
      };
    default:
      return state;
  }
};

const clearState = (state: CurrentState, step: number) => {
  if (step === 1) {
    state.selectedSurfaceEnergyMaterial = undefined;
    state.groupedSurfaceEnergyMaterials = [];
    state.selectedApplicationRequirements = [];
    state.applicationRequirements = [];
    state.selectedConstructionTypes = [];
    state.selectedParentConstructionType = undefined;
    state.selectedMaxServiceTemperature = undefined;
    state.linerTypes = [];
    state.constructionTypes = [];
    state.selectedAvailableProducts = [];
    state.availableProducts = [];
    state.selectedRequestOptions = [];
  }

  if (step === 2) {
    state.selectedApplicationRequirements = [];
    state.applicationRequirements = [];
    state.selectedConstructionTypes = [];
    state.selectedParentConstructionType = undefined;
    state.selectedMaxServiceTemperature = undefined;
    state.linerTypes = [];
    state.constructionTypes = [];
    state.selectedAvailableProducts = [];
    state.availableProducts = [];
    state.selectedRequestOptions = [];
  }

  if (step === 3) {
    state.selectedConstructionTypes = [];
    state.selectedParentConstructionType = undefined;
    state.linerTypes = [];
    state.constructionTypes = [];
    state.selectedAvailableProducts = [];
    state.availableProducts = [];
    state.selectedRequestOptions = [];
  }

  if (step === 4) {
    state.selectedAvailableProducts = [];
    state.availableProducts = [];
    state.selectedRequestOptions = [];
  }

  if (step === 5) {
    state.selectedRequestOptions = [];
  }

  if (step === 6) {
    state.procedureId = undefined;
    state.selectedLaminationMaterial = undefined;
    state.selectedSurfaceEnergyMaterial = undefined;
    state.groupedSurfaceEnergyMaterials = [];
    state.selectedApplicationRequirements = [];
    state.applicationRequirements = [];
    state.selectedConstructionTypes = [];
    state.selectedParentConstructionType = undefined;
    state.linerTypes = [];
    state.constructionTypes = [];
    state.selectedAvailableProducts = [];
    state.availableProducts = [];
    state.selectedRequestOptions = [];
    state.requestDescription = undefined;
    state.shippingDetail = new ShippingDetail();
  }

  return state;
};

export default Reducer;
