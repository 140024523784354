import {
  RegisterUser,
  ResetPassword,
  ResetPasswordToken,
  User,
  UserChangePassword,
  UserDetails,
} from "../models";
import { DeleteProfileData } from "../models/deleteProfileData";
import { UserLogin } from "../models/userLogin";
import { BaseService } from "./base.service";
import { Preferences } from "@capacitor/preferences";

export const UserService = {
  createUser: async (user: RegisterUser): Promise<User> => {
    const data = await BaseService.fetchAPI(
      `mutation($user: RegisterDataInput){
            registerUser(data: $user){
              email,
              token,
              fullName,
              isAdmin,
              marketingAgree
            }
          }`,
      {
        user: user,
      }
    );
    return data?.user;
  },
  createAdminUser: async (user: RegisterUser): Promise<UserDetails> => {
    const data = await BaseService.fetchAPI(
      `mutation($user: CreateAdministratorDataInput){
            createAdministrator(data: $user){
              firstName,
              lastName,
              email
            }
          }`,
      {
        user: user,
      }
    );
    return data?.user;
  },
  login: async (user: UserLogin): Promise<User> => {
    const data = await BaseService.fetchAPI(
      `mutation($user: LoginDataInput){
            loginUser(data: $user){
              email,
              token,
              fullName,
              isAdmin
            }
          }`,
      {
        user: user,
      }
    );

    await Preferences.set({
      key: "USUserData",
      value: JSON.stringify(data.loginUser),
    });

    return data?.loginUser;
  },
  logout: async (): Promise<void> => {
    await Preferences.remove({ key: "USUserData" });
  },
  requestResetPassword: async (resetPw: ResetPassword): Promise<boolean> => {
    const data = await BaseService.fetchAPI(
      `mutation($reset: RequestResetPasswordDataInput){
        requestResetPassword(data: $reset)
      }`,
      {
        reset: resetPw,
      }
    );

    return data;
  },
  resetPassword: async (resetPw: ResetPasswordToken): Promise<any> => {
    const data = await BaseService.fetchAPI(
      `mutation($reset: ResetPasswordDataInput){
        resetPassword(data: $reset)
      }`,
      {
        reset: resetPw,
      }
    );

    return data;
  },
  changePassword: async (changePw: UserChangePassword): Promise<boolean> => {
    const data = await BaseService.fetchAPI(
      `mutation($change: ChangePasswordDataInput){
        changePassword(data: $change)
      }`,
      {
        change: changePw,
      }
    );

    return data;
  },
  getAdmins: async (): Promise<UserDetails[]> => {
    const data = await BaseService.fetchAPI(
      `{
        administrators{
          firstName,
          lastName,
          email
        }
      }`
    );
    return data?.administrators;
  },
  deleteAdmin: async (email: string): Promise<boolean> => {
    const data = await BaseService.fetchAPI(
      `mutation($mail: DeleteAdministratorDataInput){
        deleteAdministrator(data: $mail)
      }`,
      {
        mail: { email: email },
      }
    );

    return data;
  },
  requestDeleteProfile: async (
    profileData: DeleteProfileData
  ): Promise<boolean> => {
    const data = await BaseService.fetchAPI(
      `mutation($profileData: DeleteProfileDataInput){
        requestDeleteProfile(data: $profileData)
      }`,
      {
        profileData,
      }
    );

    return data;
  },
  deleteProfile: async (profileData: DeleteProfileData): Promise<boolean> => {
    const data = await BaseService.fetchAPI(
      `mutation($profileData: DeleteProfileDataInput){
        deleteProfile(data: $profileData)
      }`,
      {
        profileData,
      }
    );

    return data;
  },
};
