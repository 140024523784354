import { Preferences } from "@capacitor/preferences";
import { User } from "../models";

export const AuthService = {
  GetCurrentUser: async (): Promise<User> => {
    const res = await Preferences.get({ key: "USUserData" });
    const user = JSON.parse(res.value!) as User;

    return user;
  },
  GetToken: async (): Promise<string> => {
    const user = await AuthService.GetCurrentUser();
    return user?.token;
  },
  IsLoggedIn: async (): Promise<boolean> => {
    var token = await AuthService.GetToken();
    return token != null;
  },
  IsAdmin: async (): Promise<boolean> => {
    var user = await AuthService.GetCurrentUser();
    return user?.isAdmin;
  },
};
