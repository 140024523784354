export const NextArrow = () => {
  return (
    <div className="bounceRight">
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.372076 5.17184L10.8392 5.17184L7.64688 8.05933L8.68663 9L13.6599 4.5L8.68663 -3.93346e-07L7.64688 0.940667L10.8392 3.82816L0.372077 3.82816L0.372076 5.17184Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
