import React, { useState } from "react";
import { SideNav } from "../shared/SideNav";

export const UserIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <button onClick={() => setIsOpen(true)} className="btn-user">
        <img src="/images/user-icon.svg" alt="" />
      </button>

      {isOpen && <SideNav onClose={() => setIsOpen(false)} />}
    </React.Fragment>
  );
};
