import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { App as CapApp } from "@capacitor/app";
import { ProductService } from "../services/productselection.service";
import { Context } from "../state";
import { RouteConstants, routes } from "../route/RouteConfig";
import { ReducerKeys } from "../state";
import { CurrentState } from "../models";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { NotFound, Spinner } from "../components";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const { promiseInProgress } = usePromiseTracker();
  const rescale = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);

    // available on ios devices with a notch
    (window as any).plugins?.safearea?.get((res: any) => {
      const offset = +res.top;
      doc.style.setProperty("--notch-margin", `${offset}px`);
      doc.style.setProperty("--app-height", `${window.innerHeight - offset}px`);
    });
  };

  window.addEventListener("resize", rescale);

  // onload
  useEffect(() => {
    rescale();
    fetchSteps();
    fetchInformationText();

    if (
      state.currentStep === 1 &&
      window.location.href
        .toLowerCase()
        .indexOf(`${RouteConstants.Steps.toLowerCase()}/`) > 0
    ) {
      // user is currently on a wizard step while the appropirate data is not loaded
      window.location.href = RouteConstants.Steps;
    }

    // register a listener for the back button when running native
    CapApp.addListener("backButton", (data) => {
      // for some reason this does not work with the history hook
      window.history.back();
    });
  }, []);

  const fetchSteps = async () => {
    const steps = await trackPromise(ProductService.getSteps());
    dispatch({ type: ReducerKeys.SET_STEPS, payload: steps });
  };

  const fetchInformationText = async () => {
    const info = await trackPromise(ProductService.getInformationText());
    dispatch({ type: ReducerKeys.SET_INFORMATION_TEXT, payload: info });
  };

  return (
    <Router>
      <ToastContainer position="top-right" autoClose={3000} />
      {promiseInProgress && <Spinner />}

      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>
    </Router>
  );
};

export default App;
