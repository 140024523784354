export class ResetPasswordToken {
  email!: string;
  password!: string;
  resetToken!: string;

  constructor(user: ResetPasswordToken) {
    if (user) {
      Object.assign(this, user);
    }
  }
}
