import { AmountPerUser } from "../models/amountPerUser";
import { Stat } from "../models/stat";
import { BaseService } from "./base.service";

export const StatsService = {
  GetStats: async (): Promise<Stat[]> => {
    const data = await BaseService.fetchAPI(
      `query{
            stats{
              type,
              description,
              totalAmount,
              amountPerStep{
                key,
                value
              }
            }
          }`
    );
    return data?.stats;
  },
  GetStatsPerUser: async (
    completed: boolean,
    step?: number
  ): Promise<AmountPerUser[]> => {
    const data = await BaseService.fetchAPI(
      `query{
        statsPerUser(data: {completed: ${completed}, step: ${
        step === undefined ? null : step
      }}){
          amountPerUser{
            key,
            value
          }       
        }
      }`
    );
    return data?.statsPerUser?.amountPerUser;
  },
};
