import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import { Footer, Header } from "../../../../components";
import {
  AdhesiveCategory,
  AvailableProduct,
  CurrentState,
  Procedure,
} from "../../../../models";
import { ProductService } from "../../../../services";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";
import { ProductResultBody } from "../../../shared/ProductResultBody";

export const Step5 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [loaded, setLoaded] = useState(false);

  // onload
  useEffect(() => {
    if (state.availableProducts.length === 0) {
      fetchData();
    } else {
      setLoaded(true);
    }
  }, []);

  const fetchData = async () => {
    let categories = [] as AdhesiveCategory[];
    const nonRequired = state.selectedApplicationRequirements?.find(
      (x) => x.recordId == 0
    );
    if (
      state.selectedApplicationRequirements.length > 0 &&
      nonRequired == null
    ) {
      categories = state.selectedApplicationRequirements
        .map((val) => val.categories)
        .reduce(function (prev, next) {
          return prev.concat(next);
        });
    } else {
      // if no application requirements are selected just pass the previous filter
      categories = state.selectedSurfaceEnergyMaterial
        ?.categories as AdhesiveCategory[];
    }

    const products = await trackPromise(
      ProductService.getAvailableProducts(
        categories,
        state.constructionTypes,
        state.selectedConstructionTypes,
        new Procedure().fromState(state),
        state.selectedMaxServiceTemperature
      )
    );
    setLoaded(true);
    dispatch({
      type: ReducerKeys.SET_AVAILABLE_PRODUCTS,
      payload: products,
    });
  };

  const selectionChanged = (items: AvailableProduct[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_AVAILABLE_PRODUCTS,
      payload: items,
    });

    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 5,
    });
  };

  return (
    <React.Fragment>
      <Header step={5} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {loaded &&
            state.availableProducts &&
            state.availableProducts.length == 0 && (
              <motion.div
                initial={MotionAnimations.slideLeft.initial}
                animate={MotionAnimations.slideLeft.animate}
                exit={MotionAnimations.slideLeft.exit}
                transition={MotionAnimations.slideLeft.transition}
              >
                <p
                  className="py-25 disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: `${state.informationText?.noProductsFound}`,
                  }}
                />
              </motion.div>
            )}

          {state.availableProducts &&
            state.availableProducts.map(
              (item: AvailableProduct, index: number) => {
                return (
                  <motion.div
                    key={index}
                    className="accordion-subitem"
                    initial={MotionAnimations.slideLeft.initial}
                    animate={MotionAnimations.slideLeft.animate}
                    exit={MotionAnimations.slideLeft.exit}
                    transition={MotionAnimations.slideLeft.transition}
                  >
                    <AccordionSubItem
                      multiSelect={true}
                      item={{
                        name: `${item.product}`,
                        ...item,
                      }}
                      selectedItems={state.selectedAvailableProducts}
                      selectionChanged={selectionChanged}
                      showScoreDialog={true}
                    >
                      {(shouldOpen: Function) => (
                        <ProductResultBody
                          item={item}
                          onOpen={(val: any) => {
                            shouldOpen(val);
                          }}
                        />
                      )}
                    </AccordionSubItem>
                  </motion.div>
                );
              }
            )}

          {loaded && (
            <motion.div
              initial={MotionAnimations.slideLeft.initial}
              animate={MotionAnimations.slideLeft.animate}
              exit={MotionAnimations.slideLeft.exit}
              transition={MotionAnimations.slideLeft.transition}
            >
              <p
                className="py-25 disclaimer"
                dangerouslySetInnerHTML={{
                  __html: `${state.informationText?.disclaimer}`,
                }}
              />
            </motion.div>
          )}
        </div>
      </main>
      <Footer step={6} showNext={state.selectedAvailableProducts.length > 0} />
    </React.Fragment>
  );
};
