import { Link } from "react-router-dom";
import { RouteConstants } from "../../route/RouteConfig";

export const CompanyLogo = () => {
  return (
    <Link to={RouteConstants.Start}>
      <img src="/images/logo.svg" className="d-inline-block" alt="logo" />
    </Link>
  );
};
