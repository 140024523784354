import { motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import { AccordionItem, Footer, Header } from "../../../../components";
import {
  AdhesiveCategory,
  CurrentState,
  GroupedMaterialItem,
  MaterialItem,
} from "../../../../models";
import { RouteConstants } from "../../../../route/RouteConfig";
import { ProductService } from "../../../../services";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";

export const Step2 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const history = useHistory();

  // onload
  useEffect(() => {
    if (state.groupedSurfaceEnergyMaterials.length === 0) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const materials = await trackPromise(
      ProductService.getSurfaceEnergyMaterials(
        state.selectedLaminationMaterial?.categories as AdhesiveCategory[]
      )
    );
    dispatch({
      type: ReducerKeys.SET_SURFACE_ENERGY_MATERIALS,
      payload: materials,
    });
  };

  const selectionChanged = (items: MaterialItem[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_SURFACE_ENERGY_MATERIAL,
      payload: items[0],
    });

    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 2,
    });

    // go to next step
    history.push(RouteConstants.Step3);
  };

  return (
    <React.Fragment>
      <Header step={2} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {state.groupedSurfaceEnergyMaterials &&
            state.groupedSurfaceEnergyMaterials.map(
              (group: GroupedMaterialItem, index: number) => {
                return (
                  <motion.div
                    key={index}
                    initial={MotionAnimations.slideLeft.initial}
                    animate={MotionAnimations.slideLeft.animate}
                    exit={MotionAnimations.slideLeft.exit}
                    transition={MotionAnimations.slideLeft.transition}
                  >
                    <AccordionItem
                      title={group.category}
                      items={group.materials}
                      isOpen={
                        group.materials.filter(
                          (x) =>
                            x.recordId ==
                            state.selectedSurfaceEnergyMaterial?.recordId
                        ).length > 0 || group.category === null
                      }
                    >
                      {(item: MaterialItem) => (
                        <AccordionSubItem
                          key={item.recordId}
                          item={item}
                          multiSelect={false}
                          selectedItems={
                            [
                              state.selectedSurfaceEnergyMaterial,
                            ] as MaterialItem[]
                          }
                          selectionChanged={selectionChanged}
                        ></AccordionSubItem>
                      )}
                    </AccordionItem>
                  </motion.div>
                );
              }
            )}
        </div>
      </main>
      <Footer step={3} showNext={state.selectedSurfaceEnergyMaterial != null} />
    </React.Fragment>
  );
};
