export class UserLogin {
  email!: string;
  password!: string;

  constructor(user: UserLogin) {
    if (user) {
      Object.assign(this, user);
    }
  }
}
