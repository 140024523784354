import React from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "..";
import { RouteConstants } from "../../route/RouteConfig";

export const NotFound = () => {
  return (
    <React.Fragment>
      <header className="header header-auto pb-2">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <UserIcon />
          </div>
        </div>
      </header>

      <main className="px-sm-15 text-gray-3 position-relative overflow-auto flex-grow d-md-flex align-items-center bg-white">
        <div className="container">
          <h2>404 - Page not found</h2>
          <p>It looks like the page you requested does not exist. </p>
        </div>
      </main>
      <footer className="footer px-sm-15 text-center bg-white">
        <div className="container">
          <Link
            to={RouteConstants.Start}
            className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
          >
            Back to home
          </Link>
        </div>
      </footer>
    </React.Fragment>
  );
};
