import { Field, Form, Formik } from "formik";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Dialog } from "..";
import { RegisterUser } from "../../models";
import { UserService } from "../../services";
import { EyeButton } from "../shared/EyeButton";
import { toast } from "react-toastify";

export const AddAdminDialog = (props: {
  onClose: Function;
  onConfirm: Function;
}) => {
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const errorRef = useRef(null);
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    repeatPassword: "",
  };

  useEffect(() => {
    if (validationErrors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);

    if (
      values.email &&
      values.firstName &&
      values.lastName &&
      values.password &&
      values.repeatPassword
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    if (values.password !== values.repeatPassword) {
      errors.push("Passwords do not match.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const createAdminUser = async (data: RegisterUser) => {
    try {
      await trackPromise(UserService.createAdminUser(data));
      toast.success("Admin created!");
      props.onConfirm();
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  return (
    <Dialog onClose={props.onClose}>
      {() => {
        return (
          <div className="w-100">
            <h2>Add administrator</h2>
            <p>
              <Formik
                initialValues={initialValues}
                validate={(values) => formValidation(values, false)}
                onSubmit={(values, { setSubmitting }) => {
                  const errors = formValidation(values, true);
                  if (errors?.length === 0) {
                    const obj = Object.assign({}, values);
                    delete (obj as any).repeatPassword;
                    createAdminUser(obj as RegisterUser);
                  }
                }}
                render={(formProps) => {
                  return (
                    <Form id="loginForm">
                      <div className="form-group">
                        <label>
                          FIRST NAME<sup>*</sup>
                        </label>
                        <Field
                          className="form-control"
                          id="inputFirstName"
                          name="firstName"
                          placeholder="First name"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          LAST NAME<sup>*</sup>
                        </label>
                        <Field
                          className="form-control"
                          id="inputLastName"
                          name="lastName"
                          placeholder="Last name"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          E-MAIL<sup>*</sup>
                        </label>
                        <Field
                          className="form-control"
                          id="inputEmail"
                          name="email"
                          placeholder="E-mail"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          PASSWORD<sup>*</sup>
                        </label>
                        <div className="position-relative">
                          <Field
                            className="form-control"
                            id="inputPassword"
                            name="password"
                            placeholder="Your password"
                            type={showPassword ? "text" : "password"}
                          />
                          <EyeButton
                            changed={(val: boolean) => setShowPassword(val)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          REPEAT PASSWORD<sup>*</sup>
                        </label>
                        <div className="position-relative">
                          <Field
                            className="form-control"
                            id="inputRepeatPassword"
                            name="repeatPassword"
                            placeholder="Repeat your password"
                            type={showRepeatPassword ? "text" : "password"}
                          />
                          <EyeButton
                            changed={(val: boolean) =>
                              setShowRepeatPassword(val)
                            }
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              ></Formik>
            </p>
            <br />
            <p className="d-flex justify-content-end">
              <a
                style={{ marginRight: "10px", color: "black" }}
                onClick={() => props.onClose()}
                className="btn small-button mb-18 text-center"
              >
                Cancel
              </a>
              <button
                disabled={!formValid}
                form="loginForm"
                type="submit"
                className="btn small-button bg-red mb-18 text-white text-center"
              >
                Create
              </button>
            </p>
            {validationErrors?.length > 0 && (
              <div ref={errorRef}>
                {validationErrors.map((err: string) => {
                  return (
                    <React.Fragment key={err}>
                      <div className="text-danger">{err}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </Dialog>
  );
};
