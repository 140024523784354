export const AccordionArrow = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1825 2.35095L13.3458 2.17652L13.1789 2.00546L12.0276 0.825411L11.8452 0.638494L11.6664 0.828844L7.0365 5.75816L2.3095 0.930428L2.12576 0.742774L1.94738 0.935531L0.816513 2.15755L0.654387 2.33274L0.821899 2.50279L6.90242 8.67544L7.08506 8.86086L7.26298 8.6709L13.1825 2.35095Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};
