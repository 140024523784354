import React, { useContext, useEffect, useState } from "react";
import { CurrentState, Procedure, Step } from "../../models";
import { Context, ReducerKeys } from "../../state";
import { NextArrow } from "../../components";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../route/RouteConfig";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { motion } from "framer-motion";
import { ProductService } from "../../services";

export const Footer = (props: {
  step: number;
  showNext?: boolean;
  children?: any;
}) => {
  const [state] = useContext(Context) as [CurrentState, Function];
  const [nextStep, setNextStep] = useState(null as unknown as Step);

  useEffect(() => {
    if (state && state.steps) {
      setNextStep(state.steps[props.step - 1]);
    }
  }, [state.steps, props.step]);

  const goToNextStep = () => {
    const next = `${RouteConstants.Steps}/${props.step}`;
    return next;
  };

  const updateProcedure = async () => {
    await ProductService.ensureProcedure(new Procedure().fromState(state));
  };

  return (
    <React.Fragment>
      {nextStep && (
        <motion.footer
          initial={MotionAnimations.fade.exit}
          animate={MotionAnimations.fade.enter}
          exit={MotionAnimations.fade.exit}
          className="footer footer-inner text-white"
          style={{ backgroundColor: nextStep.kleur }}
        >
          <div className="container">
            <div className="d-flex align-items-center">
              <Link to={RouteConstants.Start}>
                <img src="/images/footer-logo.svg" alt="" />
              </Link>

              <motion.div
                initial={MotionAnimations.fade.exit}
                animate={MotionAnimations.fade.enter}
                exit={MotionAnimations.fade.exit}
                className="ml-auto footer-link text-white d-flex align-items-center"
              >
                <Link
                  onClick={updateProcedure}
                  style={props.showNext ? { opacity: 1 } : { opacity: 0 }}
                  className="button-glow ml-auto footer-link text-white d-flex align-items-center"
                  to={goToNextStep}
                >
                  {nextStep.footerAfbeelding && (
                    <div className="footer-step">
                      <img src={nextStep.footerAfbeelding} />
                    </div>
                  )}

                  <strong>{nextStep.knopTitel}</strong>
                  <NextArrow />
                </Link>
              </motion.div>

              {props.children !== undefined && props.children()}
            </div>
          </div>
        </motion.footer>
      )}
    </React.Fragment>
  );
};
