import React from "react";
import { LinerAttribute } from "../../models";

export const LinerAttributeBody = (props: { items: LinerAttribute[] }) => {
  return (
    <React.Fragment>
      <div className="accordion-checkbox-body">
        {props.items &&
          props.items.map((type: LinerAttribute, index: number) => {
            return (
              <div
                key={index}
                className={`accordion-checkbox-item d-flex align-items-center`}
                style={{ paddingRight: "0px" }}
              >
                <div className="aci-name">{type.name}</div>
                <div className="aci-status" style={{ marginRight: "0px" }}>
                  <img
                    src={`/images/${type.compatibilityLevel.toLowerCase()}.svg`}
                    alt={type.compatibilityLevel}
                  />
                  {type.compatibilityLevel}
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};
