import React from "react";

export const SearchBox = (props: {
  filterText: string;
  onOpen?: any;
  onFilter?: any;
  onClear?: any;
  placeHolder: string;
}) => (
  <React.Fragment>
    <input
      style={{ width: "auto", backgroundColor: "transparent" }}
      className="form-control"
      placeholder={props.placeHolder}
      value={props.filterText}
      onChange={props.onFilter}
      type="search"
    />
  </React.Fragment>
);
