import {
  AdhesiveCategory,
  ConstructionType,
  GroupedMaterialItem,
  LinerType,
  Procedure,
  ProcedureData,
  RequestOption,
  Step,
  MaterialItem,
} from "../models";
import { InformationText } from "../models/informationText";
import { BaseService } from "./base.service";

export const ProductService = {
  getSteps: async (): Promise<Step[]> => {
    const data = await BaseService.fetchAPI(
      `{
        steps {
          subtitel,
          title,
          intro,
          kleur,
          statsKleur,
          afbeelding,
          knopTitel,
          footerAfbeelding
        }
      }`
    );
    return data?.steps;
  },
  getInformationText: async (): Promise<InformationText> => {
    const data = await BaseService.fetchAPI(
      `{
        informationText{
          homeTitle,
          homeDescription,
          aboutTitle,
          aboutDescription,
          disclaimer,
          startNowDialogDescription,
          registrationDisclaimer,
          noProductsFound,
          deleteProfileInformation
        }
      }`
    );
    return data?.informationText;
  },
  getRequestOptions: async (): Promise<RequestOption[]> => {
    const data = await BaseService.fetchAPI(
      `{
        requestOptions{
          recordId,
          name,
          isSample          
        }
      }`
    );
    return data?.requestOptions;
  },
  getLaminationMaterials: async (): Promise<GroupedMaterialItem[]> => {
    const data = await BaseService.fetchAPI(
      `{
        laminationMaterials{
          category,
          materials{
            recordId,
            group,
            name,
            comment,
            categories{
              recordId,
              name,
              color,
              compatibilityLevel,
              disabled,
              description,
              maxServiceTemperature,
              maxServiceTemperatureNumber,
              shear,
              bonding,
              price
            }
          }
        }
      }`
    );
    return data?.laminationMaterials;
  },
  getSurfaceEnergyMaterials: async (
    categories: AdhesiveCategory[]
  ): Promise<GroupedMaterialItem[]> => {
    const data = await BaseService.fetchAPI(
      `query ($categories: [AdhesiveCategoryDataInput]){
        surfaceEnergyMaterials(categories: $categories){
          category,        
          materials{
            recordId,
            group,
            name,
            disabled,
            dummy,
            comment
            categories{
              recordId,
              name,
              color,
              compatibilityLevel,
              disabled,
              description,
              maxServiceTemperature,
              maxServiceTemperatureNumber,
              shear,
              bonding,
              price
            }
          }
        }
      }`,
      {
        categories: categories,
      }
    );

    return data?.surfaceEnergyMaterials;
  },
  getApplicationRequirements: async (
    categories: AdhesiveCategory[]
  ): Promise<GroupedMaterialItem[]> => {
    const data = await BaseService.fetchAPI(
      `query ($categories: [AdhesiveCategoryDataInput]) {
        applicationRequirements(categories: $categories){
            recordId,
            name,
            disabled,
            isTemperatureRecord,
            categories{
              recordId,
              name,
              color,
              compatibilityLevel,
              disabled,
              description,
              maxServiceTemperature,
              maxServiceTemperatureNumber,
              shear,
              bonding,
              price
            }          
        }
      }`,
      {
        categories: categories,
      }
    );
    return data?.applicationRequirements;
  },
  getLinerTypes: async (): Promise<LinerType[]> => {
    const data = await BaseService.fetchAPI(
      `{
        linerTypes {
          recordId,
          name,
          disabled,
          linerAttributes{
            recordId,
            name,
            compatibilityLevel
          },
          categories{
            recordId,
            name,
            color,
            compatibilityLevel,
            disabled,
            description,
            maxServiceTemperature,
            maxServiceTemperatureNumber,
            shear,
            bonding,
            price
          }       
        }
      }`
    );
    return data?.linerTypes;
  },
  getConstructionTypes: async (
    categories: AdhesiveCategory[],
    maxServiceTemperatureCategory: MaterialItem | undefined
  ): Promise<ConstructionType[]> => {
    const data = await BaseService.fetchAPI(
      `query ($categories: [AdhesiveCategoryDataInput], $maxServiceTemperatureCategory: AdhesiveCategoryDataInput){
        constructionTypes(categories: $categories, maxServiceTemperatureCategory: $maxServiceTemperatureCategory){
          recordId,
          title,
          description,
          disabled,
          visual,
          isParent,
          parent{
            recordId,
            title,
            disabled,
            visual
          }
        }
      }`,
      {
        categories: categories,
        maxServiceTemperatureCategory: maxServiceTemperatureCategory,
      }
    );
    return data?.constructionTypes;
  },
  getAvailableProducts: async (
    categories: AdhesiveCategory[],
    constructionTypes: ConstructionType[],
    selectedConstructionTypes: ConstructionType[],
    procedure: Procedure,
    maxServiceTemperature?: MaterialItem
  ): Promise<any[]> => {
    const data = await BaseService.fetchAPI(
      `query ($categories: [AdhesiveCategoryDataInput], $constructionTypes: [ConstructionTypeDataInput], $selectedConstructionTypes: [ConstructionTypeDataInput], $procedure: ProcedureDataInput, $maxServiceTemperature: AdhesiveCategoryDataInput){
        availableProducts(categories: $categories, constructionTypes: $constructionTypes, selectedConstructionTypes: $selectedConstructionTypes, procedure: $procedure, maxServiceTemperature: $maxServiceTemperature){
           recordId
           product,
           construction,
           linerOptions,
           thickness,
           datasheet,
           categories{
             name
           },
           linerTypes{
             recordId,
             name,             
           },
           linerOptions,
           score,
           materialCompatibility,
           surfaceEnergyCompatibility,
           requirementsCompatibility {
             key
             value
           },
           linerTypesCompatibility {
            key
            value
          }

        }
      }`,
      {
        categories: categories,
        constructionTypes: constructionTypes,
        selectedConstructionTypes: selectedConstructionTypes,
        procedure: procedure,
        maxServiceTemperature: maxServiceTemperature,
      }
    );
    return data?.availableProducts;
  },
  ensureProcedure: async (procedure: Procedure): Promise<any> => {
    const data = await BaseService.fetchAPI(
      `mutation($procedure: ProcedureDataInput){
        ensureProcedure(data: $procedure){
          key
        }
      }`,
      {
        procedure: procedure,
      }
    );
    return data?.ensureProcedure;
  },
  getProcedures: async (): Promise<ProcedureData[]> => {
    const data = await BaseService.fetchAPI(
      `{
        procedures {
          key,
          datum,
          uur,
          products{
            product
          }
          user{
            firstName,
            lastName,
            companyName,
            companyRegion{
              name,
              recordId
            }
          }
        }
      }`
    );
    return data?.procedures;
  },

  getProcedure: async (key: string): Promise<ProcedureData> => {
    const data = await BaseService.fetchAPI(
      `query ($key: String){
        procedure(key: $key){
          key,
          datum,
          uur,
          maxServiceTemperature,
          products{
            recordId
            product,
            linerOptions
          }
          laminationMaterial{
            recordId
            name
          }
          surfaceEnergy{
            recordId
            name
          }
          extraRequirements{
            recordId
            name,
            isTemperatureRecord
          }
          type{
            recordId
            name
          }
          constructionType{
            recordId
            title
          }
          requestOptions{
            recordId
            name,
          }          
          remark
          user{
            email,
            firstName,
            lastName,          
            companyName,          
            companyRegion{
              name,
              recordId
            }
          },
          phone,
          streetAddress1,
          streetAddress2,
          city,
          state,
          zip,
          country
        }
      }`,
      {
        key: key,
      }
    );
    return data?.procedure;
  },
};
