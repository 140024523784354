const easing = [0.175, 0.85, 0.42, 0.96];

export const MotionAnimations = {
  slideLeft: {
    initial: {
      x: 500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: -500,
      opacity: 1,
    },
    transition: {
      duration: 0.5,
    },
  },
  fade: {
    exit: {
      opacity: 0,
      transition: {
        duration: 2,
        ease: easing,
      },
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 2,
        ease: easing,
      },
    },
  },
};
