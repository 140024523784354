import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { GoBackButton } from "../../components";
import { UserLogin } from "../../models/userLogin";
import { RouteConstants } from "../../route/RouteConfig";
import { UserService } from "../../services";
import { CompanyLogo } from "../shared/CompanyLogo";
import { EyeButton } from "../shared/EyeButton";

export const Login = (props: { inDialog?: boolean; callback?: Function }) => {
  const history = useHistory();
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const search = useLocation().search;
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const errorRef = useRef(null);

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (validationErrors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);

    if (values.email && values.password) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const login = async (data: UserLogin) => {
    try {
      await trackPromise(UserService.login(data));

      if (props.callback) {
        props.callback();
      } else {
        // check if we have a redirect url
        const redirectUrl = new URLSearchParams(search).get("redirectUrl");
        if (redirectUrl) {
          history.replace(redirectUrl);
        } else {
          history.push(RouteConstants.Start);
        }
      }
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex py-20">
        <div className="container mt-auto">
          <div className="logo">
            <CompanyLogo />
          </div>
        </div>
      </header>
      {!props.inDialog && (
        <div className="container">
          <div className="mb-10 px-sm-15">
            <GoBackButton />
          </div>
        </div>
      )}
      <main className="py-25 px-sm-15 position-relative overflow-auto flex-grow d-flex bg-white">
        <div className="container">
          <div className="page-info mb-25 login-info">
            <h1>Login</h1>
            <p>Please provide your username and password</p>
          </div>

          <Formik
            initialValues={initialValues}
            validate={(values) => formValidation(values, false)}
            onSubmit={(values, { setSubmitting }) => {
              const errors = formValidation(values, true);
              if (errors?.length === 0) {
                login(values);
              }
            }}
            render={(formProps) => {
              return (
                <Form id="loginForm">
                  <div className="form-group">
                    <label>
                      E-MAIL<sup>*</sup>
                    </label>
                    <Field
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      placeholder="Your e-mail"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputPassword"
                        name="password"
                        placeholder="Your password"
                        type={showPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) => setShowPassword(val)}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          ></Formik>

          <Link
            to={RouteConstants.RequestResetPassword}
            className="btn-link btn-link-sm text-blue"
          >
            I forgot my password
          </Link>

          {validationErrors?.length > 0 && (
            <div ref={errorRef} className="pt-20">
              {validationErrors.map((err: string) => {
                return (
                  <React.Fragment key={err}>
                    <div className="text-danger">{err}</div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </main>

      <footer className="footer px-sm-15 text-center bg-white">
        <div className="container">
          <button
            disabled={!formValid}
            form="loginForm"
            type="submit"
            className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
          >
            Login
          </button>

          <div className="d-flex justify-content-space-between">
            <Link to={RouteConstants.Register} className="btn-link text-blue">
              I want to register
            </Link>

            <Link
              to={RouteConstants.DeleteAccount}
              className="btn-link text-blue"
            >
              Delete my account
            </Link>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};
