import {
  About,
  Login,
  Register,
  RequestResetPassword,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  ThankYou,
  ResetPassword,
  ChangePassword,
  Requests,
  RequestDetail,
  Stats,
  Admins,
  UpdateApp,
} from "../components";
import { Start } from "../components";
import { DeleteAccount } from "../components/login/DeleteAccount";

export const RouteConstants = {
  Start: "/",
  About: "/about",
  Login: "/login",
  Register: "/register",
  DeleteAccount: "/deleteaccount",
  Requests: "/requests",
  RequestDetail: "/requests/:id",
  RequestResetPassword: "/requestresetpassword",
  ResetPassword: "/resetpassword/:token",
  ChangeProfile: "/changeprofile",
  Steps: "/selectionsteps",
  Step2: "/selectionsteps/2",
  Step3: "/selectionsteps/3",
  Step4: "/selectionsteps/4",
  Step5: "/selectionsteps/5",
  Step6: "/selectionsteps/6",
  ThankYou: "/thankyou",
  Stats: "/stats",
  Admins: "/admins",
  UpdateApp: "/update",
};

export const routes = [
  {
    path: RouteConstants.Start,
    component: Start,
    exact: true,
  },
  {
    path: RouteConstants.UpdateApp,
    component: UpdateApp,
  },
  {
    path: RouteConstants.About,
    component: About,
  },
  {
    path: RouteConstants.Login,
    component: Login,
  },
  {
    path: RouteConstants.Register,
    component: Register,
  },
  {
    path: RouteConstants.Requests,
    component: Requests,
    exact: true,
  },
  {
    path: RouteConstants.RequestDetail,
    component: RequestDetail,
    exact: true,
  },
  {
    path: RouteConstants.RequestResetPassword,
    component: RequestResetPassword,
  },
  {
    path: RouteConstants.ResetPassword,
    component: ResetPassword,
    exact: true,
  },
  {
    path: RouteConstants.ChangeProfile,
    component: ChangePassword,
  },
  {
    path: RouteConstants.Steps,
    component: Step1,
    exact: true,
  },
  {
    path: RouteConstants.Step2,
    component: Step2,
  },
  {
    path: RouteConstants.Step3,
    component: Step3,
  },
  {
    path: RouteConstants.Step4,
    component: Step4,
  },
  {
    path: RouteConstants.Step5,
    component: Step5,
  },
  {
    path: RouteConstants.Step6,
    component: Step6,
  },
  {
    path: RouteConstants.ThankYou,
    component: ThankYou,
  },
  {
    path: RouteConstants.Stats,
    component: Stats,
  },
  {
    path: RouteConstants.Admins,
    component: Admins,
  },
  {
    path: RouteConstants.DeleteAccount,
    component: DeleteAccount,
  },
];
