import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useLocation } from "react-router-dom";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { AccordionItem, GoBackButton } from "../../components";
import { Region, CurrentState, RegisterUser } from "../../models";
import { RouteConstants } from "../../route/RouteConfig";
import { ProductService, UserService } from "../../services";
import { toast } from "react-toastify";
import { EyeButton } from "../shared/EyeButton";
import { Context } from "../../state";

export const Register = () => {
  const history = useHistory();
  const search = useLocation().search;
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const errorRef = useRef(null);
  const [state] = useContext(Context) as [CurrentState];
  const [marketingAgree, setMarketingAgree] = useState(false);

  useEffect(() => {
    if (validationErrors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);

    if (
      values.lastName &&
      values.firstName &&
      values.email &&
      values.password &&
      values.repeatPassword &&
      values.companyName
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    if (values.password !== values.repeatPassword) {
      errors.push("Passwords do not match.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const createUser = async (user: RegisterUser) => {
    try {
      await trackPromise(UserService.createUser(user));
      await trackPromise(
        UserService.login({ email: user.email, password: user.password })
      );

      toast.success("Registration successful!");

      // check if we have a redirect url
      const redirectUrl = new URLSearchParams(search).get("redirectUrl");
      if (redirectUrl) {
        history.replace(redirectUrl);
      } else {
        history.push(RouteConstants.Start);
      }
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  let initialValues = {
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    companyName: "",
  } as RegisterUser;

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton />
          </div>
          <div className="page-info px-0">
            <h1>Register</h1>
            <p>Please provide your details</p>
          </div>
        </div>
      </header>

      <main className="px-sm-15 py-25 position-relative overflow-auto flex-grow d-md-flex bg-white">
        <div className="container">
          <Formik
            initialValues={initialValues}
            validate={(values) => formValidation(values, false)}
            onSubmit={(values, { setSubmitting }) => {
              const errors = formValidation(values, true);
              if (errors?.length === 0) {
                const obj = Object.assign({}, values);
                obj.marketingAgree = marketingAgree;

                delete obj.repeatPassword;
                createUser(obj);
              }
            }}
            render={(formProps) => {
              return (
                <Form id="registerForm">
                  <AccordionItem
                    title={"Your info"}
                    items={[{ key: "user" }]}
                    isOpen={true}
                  >
                    {(item: any) => (
                      <div key="user" className="py-25">
                        <div className="form-group  d-flex">
                          <div
                            style={{ minWidth: "50%", paddingRight: "10px" }}
                          >
                            <label>
                              NAME<sup>*</sup>
                            </label>
                            <Field
                              className="form-control"
                              id="inputNaam"
                              name="lastName"
                              placeholder="Your name"
                            />
                          </div>
                          <div style={{ minWidth: "50%" }}>
                            <label>
                              FIRST NAME<sup>*</sup>
                            </label>
                            <Field
                              className="form-control"
                              id="inputFistName"
                              name="firstName"
                              placeholder="Your first name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            E-MAIL ADDRESS<sup>*</sup>
                          </label>
                          <Field
                            className="form-control"
                            id="inputEmail"
                            name="email"
                            placeholder="Your email"
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            PASSWORD<sup>*</sup>
                          </label>
                          <div className="position-relative">
                            <Field
                              className="form-control"
                              id="inputPassword"
                              name="password"
                              placeholder="Your password"
                              type={showPassword ? "text" : "password"}
                            />
                            <EyeButton
                              changed={(val: boolean) => setShowPassword(val)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            REPEAT PASSWORD<sup>*</sup>
                          </label>
                          <div className="position-relative">
                            <Field
                              className="form-control"
                              id="inputRepeatPassword"
                              name="repeatPassword"
                              placeholder="Repeat your password"
                              type={showRepeatPassword ? "text" : "password"}
                            />
                            <EyeButton
                              changed={(val: boolean) =>
                                setShowRepeatPassword(val)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </AccordionItem>

                  <AccordionItem
                    title={"Company info"}
                    items={[{ key: "company" }]}
                    isOpen={true}
                  >
                    {(item: any) => (
                      <div key="company" className="py-25">
                        <div className="form-group">
                          <label>
                            NAME<sup>*</sup>
                          </label>
                          <Field
                            className="form-control"
                            id="inputCompanyName"
                            name="companyName"
                            placeholder="Your company name"
                          />
                        </div>
                      </div>
                    )}
                  </AccordionItem>

                  <p
                    className="py-25 disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: `${state.informationText?.registrationDisclaimer}`,
                    }}
                  />
                  <div className="disclaimer pb-15">
                    <input
                      id="check"
                      type="checkbox"
                      checked={marketingAgree}
                      onChange={() => setMarketingAgree(!marketingAgree)}
                    />
                    <label htmlFor="check">
                      {" "}
                      Yes, I agree to receive information about products and
                      services from Avery Dennison by email. Avery Dennison
                      gathers statistics around email opening and clicks using
                      industry standard technologies for our internal analytics
                      purposes and to improve the quality and relevance of our
                      emails.
                    </label>
                  </div>

                  {validationErrors?.length > 0 && (
                    <div ref={errorRef} className="py-20">
                      {validationErrors.map((err: string) => {
                        return (
                          <React.Fragment key={err}>
                            <div className="text-danger">{err}</div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                </Form>
              );
            }}
          ></Formik>
        </div>
      </main>

      <footer className="footer text-center p-0 bg-white">
        <button
          form="registerForm"
          disabled={!formValid}
          type="submit"
          className="btn bg-blue text-white rounded-0 btn-xl text-center btn-block"
        >
          Register
        </button>
      </footer>
    </motion.div>
  );
};
