import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Login } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { User } from "../../models";
import { RouteConstants } from "../../route/RouteConfig";
import { UserService } from "../../services";
import { AuthService } from "../../services/auth.service";

export const SideNav = (props: { onClose: Function }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null as unknown as User);

  useEffect(() => {
    (async function () {
      const user = await AuthService.GetCurrentUser();
      setUserInfo(user);
      setLoggedIn(user?.token != null);
    })();
  }, []);

  return (
    <div
      className="navbar active vh-100"
      onClick={(ev) => {
        const elm = ev.target as HTMLElement;
        if (elm.classList.contains("navbar")) {
          props.onClose();
        }
      }}
    >
      <motion.div
        initial={MotionAnimations.slideLeft.initial}
        animate={MotionAnimations.slideLeft.animate}
        exit={MotionAnimations.slideLeft.exit}
        transition={MotionAnimations.slideLeft.transition}
      >
        <div className="navbar-content flex-column d-flex vh-100">
          <button
            onClick={() => {
              props.onClose();
            }}
            className="btn-close btn-reset"
          >
            <img src="/images/close.svg" className="d-block" alt="close" />
          </button>
          {!loggedIn && (
            <Login
              inDialog={true}
              callback={() => {
                props.onClose();
              }}
            />
          )}

          {loggedIn && (
            <div className="navbar-header header-gray">
              <h6 className="group-title text-dark-gray">
                {userInfo.isAdmin ? "ADMINISTRATOR" : "USER"}
              </h6>
              <h2>{userInfo.fullName}</h2>
            </div>
          )}

          {loggedIn && (
            <React.Fragment>
              <div className="navbar-body overflow-auto flex-grow">
                <ul>
                  <li>
                    <Link to={RouteConstants.Requests}>
                      Requests
                      <svg
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="#A6A8AB"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.372076 5.17184L10.8392 5.17184L7.64688 8.05933L8.68663 9L13.6599 4.5L8.68663 -3.93346e-07L7.64688 0.940667L10.8392 3.82816L0.372077 3.82816L0.372076 5.17184Z"
                          fill="#A6A8AB"
                        />
                      </svg>
                    </Link>
                  </li>
                  {userInfo.isAdmin && (
                    <React.Fragment>
                      <li>
                        <Link to={RouteConstants.Stats}>
                          Stats
                          <svg
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="#A6A8AB"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.372076 5.17184L10.8392 5.17184L7.64688 8.05933L8.68663 9L13.6599 4.5L8.68663 -3.93346e-07L7.64688 0.940667L10.8392 3.82816L0.372077 3.82816L0.372076 5.17184Z"
                              fill="#A6A8AB"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link to={RouteConstants.Admins}>
                          Administrators
                          <svg
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="#A6A8AB"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.372076 5.17184L10.8392 5.17184L7.64688 8.05933L8.68663 9L13.6599 4.5L8.68663 -3.93346e-07L7.64688 0.940667L10.8392 3.82816L0.372077 3.82816L0.372076 5.17184Z"
                              fill="#A6A8AB"
                            />
                          </svg>
                        </Link>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>

              <div className="navbar-footer mt-auto footer-gray d-flex align-items-center">
                <Link
                  to={RouteConstants.ChangeProfile}
                  className="navbar-profile"
                >
                  <img src="/images/user-icon.svg" alt="icon" /> Change profile
                </Link>
                <button
                  onClick={async () => {
                    await UserService.logout();
                    setLoggedIn(false);
                  }}
                  className="text-blue ml-auto btn-link btn-info-link"
                >
                  logout
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </motion.div>
    </div>
  );
};
