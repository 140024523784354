import { Dialog } from "..";

export const ConfirmDeleteDialog = (props: {
  onClose: Function;
  onConfirm: Function;
  email: string;
}) => {
  return (
    <Dialog onClose={props.onClose}>
      {() => {
        return (
          <div className="w-100">
            <h2>Confirm delete</h2>
            <p>Are you sure you want to delete account {props.email}?</p>
            <br />
            <p className="d-flex justify-content-end">
              <a
                style={{ marginRight: "10px", color: "black" }}
                onClick={() => props.onClose()}
                className="btn small-button mb-18 text-center"
              >
                Cancel
              </a>
              <a
                onClick={() => props.onConfirm()}
                className="btn  small-button bg-red mb-18 text-white text-center"
              >
                Confirm
              </a>
            </p>
          </div>
        );
      }}
    </Dialog>
  );
};
