import { Field, Form, Formik, getIn } from "formik";
import { motion } from "framer-motion";
import { GraphQLError } from "graphql";
import React, { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CompanyLogo, GoBackButton } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { User, UserChangePassword } from "../../models";
import { UserService } from "../../services";
import { EyeButton } from "../shared/EyeButton";
import { AuthService } from "../../services/auth.service";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../route/RouteConfig";

export const ChangeProfile = () => {
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const [success, setSuccess] = useState(false);
  const errorRef = useRef(null);
  const [userInfo, setUserInfo] = useState(null as unknown as User);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  } as UserChangePassword;

  useEffect(() => {
    if (validationErrors?.length > 0 || success) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors, success]);

  useEffect(() => {
    (async function () {
      const user = await AuthService.GetCurrentUser();
      setUserInfo(user);
    })();
  }, []);

  const formValidation = (values: any, fullValidation: boolean) => {
    setSuccess(false);
    setValidationErrors([]);

    if (
      values.currentPassword &&
      values.newPassword &&
      values.repeatNewPassword
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (values.repeatNewPassword !== values.newPassword) {
      errors.push("Passwords do not match.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const changePassword = async (data: UserChangePassword) => {
    const obj = Object.assign({}, data);
    delete obj.repeatNewPassword;

    try {
      await trackPromise(UserService.changePassword(obj));
      setSuccess(true);
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex py-20">
        <div className="container mt-auto">
          <div className="logo">
            <CompanyLogo />
          </div>
        </div>
      </header>

      <div className="container">
        <div className="mb-10 px-sm-15">
          <GoBackButton />
        </div>
      </div>
      <main className="py-25 px-sm-15 position-relative overflow-auto flex-grow d-flex bg-white">
        <div className="container">
          <div className="page-info mb-25 login-info">
            <h1>Change password</h1>
            <p>Please provide your current and new password</p>
          </div>

          <Formik
            initialValues={initialValues}
            validate={(values) => formValidation(values, false)}
            onSubmit={(values, { setSubmitting }) => {
              const errors = formValidation(values, true);
              if (errors?.length === 0) {
                changePassword(values);
              }
            }}
            render={(formProps) => {
              return (
                <Form id="changeForm" className="mb-25">
                  <div className="form-group">
                    <label>
                      CURRENT PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputPassword"
                        name="currentPassword"
                        placeholder="Your current password"
                        type={showPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) => setShowPassword(val)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      NEW PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputNewPassword"
                        name="newPassword"
                        placeholder="Your new password"
                        type={showNewPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) => setShowNewPassword(val)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      REPEAT NEW PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputRepeatNewPassword"
                        name="repeatNewPassword"
                        placeholder="Repeat your new password"
                        type={showRepeatNewPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) =>
                          setShowRepeatNewPassword(val)
                        }
                      />
                    </div>
                  </div>
                  <div ref={errorRef}>
                    {validationErrors?.length > 0 && (
                      <div className="pt-20">
                        {validationErrors.map((err: string) => {
                          return (
                            <React.Fragment key={err}>
                              <div className="text-danger">{err}</div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}

                    {success && (
                      <React.Fragment>
                        <div className="text-success pt-20">
                          Password changed successfully
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Form>
              );
            }}
          ></Formik>
          <button
            disabled={!formValid}
            form="changeForm"
            type="submit"
            className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
          >
            Reset
          </button>
          <div className="page-info  pt-20 login-info">
            <h1>Delete profile</h1>
            <Link
              to={`${RouteConstants.DeleteAccount}?email=${userInfo?.email}`}
              className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
            >
              Delete my data
            </Link>
          </div>
        </div>
      </main>
    </motion.div>
  );
};
