import React, { useContext, useEffect } from "react";
import {
  CurrentState,
  GroupedMaterialItem,
  MaterialItem,
  Procedure,
} from "../../../../models";
import { ProductService } from "../../../../services";
import { ReducerKeys, Context } from "../../../../state";
import { Footer, Header, AccordionItem } from "../../..";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";
import { trackPromise } from "react-promise-tracker";
import { motion } from "framer-motion";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../../route/RouteConfig";

export const Step1 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const history = useHistory();

  // onload
  useEffect(() => {
    if (state.groupedLaminationMaterials?.length === 0) {
      fetchData();
    }

    if (state.procedureId === undefined) {
      ensureProcedure();
    }
  }, []);

  const fetchData = async () => {
    const materials = await trackPromise(
      ProductService.getLaminationMaterials()
    );
    dispatch({
      type: ReducerKeys.SET_LAMINATION_MATERIALS,
      payload: materials,
    });
  };

  const ensureProcedure = async () => {
    const res = await ProductService.ensureProcedure(new Procedure());
    dispatch({
      type: ReducerKeys.SET_PROCEDURE_ID,
      payload: res.key,
    });
  };

  const selectionChanged = (items: MaterialItem[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_LAMINATION_MATERIAL,
      payload: items[0],
    });

    dispatch({
      type: ReducerKeys.CLEAR_STEPS,
      payload: 1,
    });

    // go to next step
    history.push(RouteConstants.Step2);
  };

  return (
    <React.Fragment>
      <Header step={1} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {state.groupedLaminationMaterials &&
            state.groupedLaminationMaterials.map(
              (group: GroupedMaterialItem, index: number) => {
                const isOpen =
                  group.materials.filter(
                    (x) =>
                      x.recordId == state.selectedLaminationMaterial?.recordId
                  ).length > 0;
                return (
                  <motion.div
                    key={index}
                    initial={MotionAnimations.slideLeft.initial}
                    animate={MotionAnimations.slideLeft.animate}
                    exit={MotionAnimations.slideLeft.exit}
                    transition={MotionAnimations.slideLeft.transition}
                  >
                    <AccordionItem
                      title={group.category}
                      items={group.materials}
                      isOpen={isOpen}
                    >
                      {(item: MaterialItem) => (
                        <AccordionSubItem
                          key={item.recordId}
                          item={item}
                          multiSelect={false}
                          selectedItems={
                            [state.selectedLaminationMaterial] as MaterialItem[]
                          }
                          selectionChanged={selectionChanged}
                        ></AccordionSubItem>
                      )}
                    </AccordionItem>
                  </motion.div>
                );
              }
            )}
        </div>
      </main>

      <Footer step={2} showNext={state.selectedLaminationMaterial != null} />
    </React.Fragment>
  );
};
