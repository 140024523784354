import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CurrentState } from "../../../../models";
import { ShippingDetail } from "../../../../models/shippingDetail";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionItem } from "../../../shared/AccordionItem";

export const SampleForm = (props: { isFormValid: Function }) => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const errorRef = useRef(null);

  useEffect(() => {
    if (state.shippingDetail == undefined) {
      dispatch({
        type: ReducerKeys.SET_SHIPPINGDETAILS,
        payload: new ShippingDetail(),
      });
    }

    formValidation(state.shippingDetail, true);

    //unmount
    return function cleanup() {
      props.isFormValid(true);
    };
  }, []);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);

    if (
      values.streetAddress1 &&
      values.city &&
      values.state &&
      values.zip &&
      values.country &&
      values.phone
    ) {
      props.isFormValid(true);
    } else {
      props.isFormValid(false);
    }

    dispatch({
      type: ReducerKeys.SET_SHIPPINGDETAILS,
      payload: values,
    });

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    setValidationErrors(errors);
    return errors;
  };

  return (
    <Formik
      initialValues={state.shippingDetail}
      validate={(values) => formValidation(values, false)}
      onSubmit={(values, { setSubmitting }) => {}}
      render={(formProps) => {
        return (
          <Form id="sampleForm" style={{ marginLeft: "20px" }}>
            <AccordionItem
              key={"sample"}
              title={"Shipping details"}
              items={[{ key: "shippingDetails" }]}
              isOpen={true}
            >
              {(item: any) => (
                <div className="py-25">
                  <div className="form-group">
                    <label>
                      Phone number<sup>*</sup>
                    </label>
                    <Field
                      className="form-control"
                      name="phone"
                      placeholder="Your phone number"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Street Address 1<sup>*</sup>
                    </label>
                    <Field
                      className="form-control"
                      name="streetAddress1"
                      placeholder="Your street address 1"
                    />
                  </div>
                  <div className="form-group">
                    <label>Street Address 2</label>
                    <Field
                      className="form-control"
                      name="streetAddress2"
                      placeholder="Your street address 2"
                    />
                  </div>
                  <div className="form-group  d-flex">
                    <div style={{ minWidth: "50%", paddingRight: "10px" }}>
                      <label>
                        CITY<sup>*</sup>
                      </label>
                      <Field
                        className="form-control"
                        name="city"
                        placeholder="Your city"
                      />
                    </div>
                    <div style={{ minWidth: "50%" }}>
                      <label>
                        STATE/PROVINCE<sup>*</sup>
                      </label>
                      <Field
                        className="form-control"
                        name="state"
                        placeholder="Your state or province"
                      />
                    </div>
                  </div>
                  <div className="form-group  d-flex">
                    <div style={{ minWidth: "50%", paddingRight: "10px" }}>
                      <label>
                        ZIP CODE<sup>*</sup>
                      </label>
                      <Field
                        className="form-control"
                        name="zip"
                        placeholder="Your zip code"
                      />
                    </div>
                    <div style={{ minWidth: "50%" }}>
                      <label>
                        COUNTRY<sup>*</sup>
                      </label>
                      <Field
                        className="form-control"
                        name="country"
                        placeholder="Your country"
                      />
                    </div>
                  </div>
                </div>
              )}
            </AccordionItem>

            {validationErrors?.length > 0 && (
              <div ref={errorRef} className="py-20">
                {validationErrors.map((err: string) => {
                  return (
                    <React.Fragment key={err}>
                      <div className="text-danger">{err}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </Form>
        );
      }}
    ></Formik>
  );
};
