import { GraphQLError } from "graphql";
import { toast } from "react-toastify";
import { AuthService } from "./auth.service";
import { UserService } from "./user.service";

export const BaseService = {
  fetchAPI: async (query: string, variables?: any): Promise<any> => {
    try {
      let appSettings: any;
      await fetch(`/appsettings.json?v=` + new Date().getMilliseconds())
        .then((response) => response.json())
        .then((data) => (appSettings = data));

      const token = await AuthService.GetToken();
      const res = await fetch(`${appSettings.BackEndUrl}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Version: appSettings.Version,
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      if (res.status === 409) {
        // version number is not correct
        const updateUrl = `${window.location.origin}/update`;
        if (window.location.href !== updateUrl) {
          window.location.href = updateUrl;
        }
        return Promise.reject(res);
      }

      const json = await res.json();
      if (json.errors) {
        var errors = json.errors.map((graphError: GraphQLError) => {
          // return list of validation errors
          if (graphError.extensions?.ValidationException) {
            return graphError.extensions?.ValidationException.map(
              (innerEx: GraphQLError) => {
                return innerEx.message;
              }
            );
          } else {
            if (graphError.extensions?.code === "403") {
              UserService.logout();
            }

            return graphError.extensions?.message === undefined
              ? graphError?.message
              : graphError.extensions?.message;
          }
        });
        return Promise.reject(errors);
      }

      if (res.ok) {
        return json.data;
      }

      return Promise.reject(res.body);
    } catch (e: any) {
      toast.error("An unexpected error occured.");
      return Promise.reject();
    }
  },
};
