import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useLocation } from "react-router-dom";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { AccordionItem, GoBackButton } from "..";
import { Region, CurrentState, RegisterUser } from "../../models";
import { RouteConstants } from "../../route/RouteConfig";
import { ProductService, UserService } from "../../services";
import { toast } from "react-toastify";
import { EyeButton } from "../shared/EyeButton";
import { Context } from "../../state";
import { DeleteProfileData } from "../../models/deleteProfileData";

export const DeleteAccount = () => {
  const [formValid, setFormValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const [succeeded, setSucceeded] = useState(false);
  const errorRef = useRef(null);
  const [state] = useContext(Context) as [CurrentState];

  useEffect(() => {
    if (validationErrors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  useEffect(() => {
    setFormValid(!!initialEmail);
  }, []);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);

    if (values.email) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const postData = async (values?: DeleteProfileData) => {
    var errors = [];
    if (!token) {
      errors = formValidation(values, true);
    }
    if (token || errors?.length === 0) {
      const obj = Object.assign({ token }, values);
      if (token) {
        var response = await trackPromise(UserService.deleteProfile(obj));
        if (response) {
          setSucceeded(true);
          await trackPromise(UserService.logout());
        }
      } else {
        await trackPromise(UserService.requestDeleteProfile(obj));

        setSucceeded(true);
      }
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialEmail = queryParams.get("email") || "";
  const token = queryParams.get("token");

  let initialValues = {
    email: initialEmail,
  } as DeleteProfileData;

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton url={RouteConstants.Start} />
          </div>
          <div className="page-info px-0">
            <h1>Delete account</h1>
            <p>Delete your account by providing your email</p>
          </div>
        </div>
      </header>

      <main className="px-sm-15 py-25 position-relative overflow-auto flex-grow d-md-flex bg-white">
        <div className="container">
          {!succeeded && !token && (
            <Formik
              initialValues={initialValues}
              validate={(values) => formValidation(values, false)}
              onSubmit={postData}
              render={(formProps) => {
                return (
                  state.informationText && (
                    <Form id="deleteAccountForm">
                      <div key="user" className="py-25">
                        <div className="form-group">
                          <label>
                            E-MAIL ADDRESS<sup>*</sup>
                          </label>
                          <Field
                            className="form-control"
                            id="inputEmail"
                            name="email"
                            placeholder="Your email"
                          />
                        </div>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${state.informationText?.deleteProfileInformation}`,
                        }}
                      />
                      <p
                        className="py-25 disclaimer"
                        dangerouslySetInnerHTML={{
                          __html: `${state.informationText?.registrationDisclaimer}`,
                        }}
                      />

                      {validationErrors?.length > 0 && (
                        <div ref={errorRef} className="py-20">
                          {validationErrors.map((err: string) => {
                            return (
                              <React.Fragment key={err}>
                                <div className="text-danger">{err}</div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    </Form>
                  )
                );
              }}
            ></Formik>
          )}
          {!succeeded && token && (
            <p>
              <strong>Are you sure you want to delete your account?</strong>
              <br />
              By pressing delete, all your data will be scrambled.
            </p>
          )}
          {succeeded && !token && (
            <p>
              <strong>
                The request to delete your account has successfully been sent!
              </strong>
              <br />
              Please check your email to confirm the deletion.
            </p>
          )}
          {succeeded && token && (
            <p>
              <strong>Your data has succesfully been scrambled</strong>
              <br />
              You are now logged out
            </p>
          )}
        </div>
      </main>
      <footer className="footer text-center p-0 bg-white">
        {!succeeded && !token && (
          <button
            form="deleteAccountForm"
            disabled={!formValid}
            type="submit"
            className="btn bg-red text-white rounded-0 btn-xl text-center btn-block"
          >
            Delete account
          </button>
        )}
        {!succeeded && token && (
          <button
            form="deleteAccountForm"
            onClick={() => postData()}
            className="btn bg-red text-white rounded-0 btn-xl text-center btn-block"
          >
            Delete account
          </button>
        )}
      </footer>
    </motion.div>
  );
};
