import React, { useState } from "react";
import { AccordionArrow } from "./AccordionArrow";

export const AccordionItem = (props: {
  title: string;
  items: any[];
  children?: any;
  isOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  return (
    <div
      className={`accordion-item ${isOpen ? "active" : ""} ${
        props.title === null ? "no-group" : ""
      }`}
    >
      <div
        onClick={(event) => setIsOpen(!isOpen)}
        className="accordion-header d-flex"
      >
        {props.title && (
          <React.Fragment>
            <h4>{props.title}</h4>
            <button
              onClick={(event) => setIsOpen(!isOpen)}
              type="button"
              className="accordion-arrow ml-auto"
            >
              <AccordionArrow />
            </button>
          </React.Fragment>
        )}
      </div>
      <div className="accordion-body">
        {props.items &&
          props.items.map((item: any, index: number) => {
            return props.children(item, index);
          })}
      </div>
    </div>
  );
};
