import { Link } from "react-router-dom";
import { RouteConstants } from "../../route/RouteConfig";
import { Preferences } from "@capacitor/preferences";
import { useEffect, useState } from "react";

export const NewSelectionButton = () => {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    (async function () {
      const shouldHide =
        (await Preferences.get({ key: "hideAbout" }))?.value == "true";
      if (shouldHide) {
        setHide(shouldHide);
      }
    })();
  }, []);

  return (
    <Link
      to={!hide ? RouteConstants.About : RouteConstants.Steps}
      className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
    >
      Start a new selection
    </Link>
  );
};
