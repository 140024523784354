import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NewSelectionButton, UserIcon } from "../..";
import { MotionAnimations } from "../../../animations/MotionAnimations";
import { CurrentState } from "../../../models";
import { RouteConstants } from "../../../route/RouteConfig";
import { AuthService } from "../../../services/auth.service";
import { Context, ReducerKeys } from "../../../state";
import { CompanyLogo } from "../..";

export const Start = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [loggedIn, setLoggedIn] = useState(false);

  dispatch({
    type: ReducerKeys.CLEAR_STEPS,
    payload: 6,
  });

  useEffect(() => {
    (async function () {
      setLoggedIn(await AuthService.IsLoggedIn());
    })();
  }, []);

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex">
        <div className="container">
          <div className="logo d-flex justify-content-end">
            <CompanyLogo />
            <div className="ml-35">
              <UserIcon />
            </div>
          </div>
        </div>
      </header>

      <img src="images/home-image.png" className="home-image" alt="" />

      {state.informationText && (
        <React.Fragment>
          <main className="home-content position-relative overflow-auto text-white flex-grow bg-light-blue">
            <div className="container">
              <h1
                dangerouslySetInnerHTML={{
                  __html: `${state.informationText?.homeTitle}`,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: `${state.informationText?.homeDescription}`,
                }}
              />
            </div>
          </main>
          <footer className="footer px-sm-15 text-center bg-white">
            <div className="container">
              <NewSelectionButton />
            </div>
            {!loggedIn && (
              <React.Fragment>
                <span style={{ fontSize: "13px" }}>
                  If you're already a registered user,{" "}
                </span>
                <Link to={RouteConstants.Login} className="btn-link text-blue">
                  login here
                </Link>
                .
              </React.Fragment>
            )}
          </footer>
        </React.Fragment>
      )}
    </motion.div>
  );
};
