import React, { useContext } from "react";
import {
  AvailableProduct,
  ConstructionType,
  CurrentState,
  MaterialItem,
} from "../../models";
import { Context } from "../../state";

export const MySelection = (props: { availableProduct?: AvailableProduct }) => {
  const [state] = useContext(Context) as [CurrentState];

  return (
    <div className="w-100">
      <div className="accordion-checkbox-result accordion-product">
        <h2>Your selection</h2>
        <div>
          <div
            className="pb-12 accordion-product-title"
            style={{ color: state.steps[0].kleur }}
          >
            {state.steps[0].title}
          </div>
          <div className="d-flex">
            {state.selectedLaminationMaterial?.name}
            {props.availableProduct && (
              <div className="aci-status" style={{ marginRight: "0px" }}>
                <img
                  src={`/images/${props.availableProduct.materialCompatibility.toLowerCase()}.svg`}
                  alt={props.availableProduct.materialCompatibility}
                />
                {props.availableProduct.materialCompatibility}
              </div>
            )}
          </div>
        </div>
        <div>
          <div
            className="pb-12 accordion-product-title"
            style={{ color: state.steps[1].kleur }}
          >
            {state.steps[1].title}
          </div>
          <div className="d-flex">
            {state.selectedSurfaceEnergyMaterial?.name}
            {!state.selectedSurfaceEnergyMaterial?.dummy &&
              props.availableProduct && (
                <div className="aci-status" style={{ marginRight: "0px" }}>
                  <img
                    src={`/images/${props.availableProduct.surfaceEnergyCompatibility.toLowerCase()}.svg`}
                    alt={props.availableProduct.surfaceEnergyCompatibility}
                  />
                  {props.availableProduct.surfaceEnergyCompatibility}
                </div>
              )}
          </div>
        </div>
        <div>
          <div
            className="pb-12 accordion-product-title"
            style={{ color: state.steps[2].kleur }}
          >
            {state.steps[2].title}
          </div>
          <div>
            {state.selectedApplicationRequirements?.map(
              (value: MaterialItem, index: number) => {
                const score =
                  props.availableProduct?.requirementsCompatibility.find(
                    (x) => x.key === value.recordId
                  );
                return (
                  <div
                    className={
                      index != state.selectedApplicationRequirements.length - 1
                        ? "pb-15"
                        : ""
                    }
                    key={value.recordId}
                  >
                    <div className="d-flex">
                      {value.name}
                      {!value.isTemperatureRecord && score && (
                        <div
                          className="aci-status"
                          style={{ marginRight: "0px" }}
                        >
                          <img
                            src={`/images/${score.value.toLowerCase()}.svg`}
                            alt={score.value}
                          />
                          {score.value}
                        </div>
                      )}
                      {value.isTemperatureRecord &&
                        state.selectedMaxServiceTemperature && (
                          <div
                            className="aci-status"
                            style={{ marginRight: "0px" }}
                          >
                            {state.selectedMaxServiceTemperature.name}
                          </div>
                        )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div>
          <div
            className="pb-12 accordion-product-title"
            style={{ color: state.steps[3].kleur }}
          >
            {state.steps[3].title}
          </div>
          <div>
            {state.selectedConstructionTypes?.map(
              (value: ConstructionType, index: number) => {
                return (
                  <div
                    className={
                      index != state.selectedConstructionTypes.length - 1
                        ? "pb-15"
                        : ""
                    }
                    key={value.recordId}
                  >
                    <div className="d-flex">{value.title}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
