import { motion } from "framer-motion";
import { RequestOptions } from "node:https";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory } from "react-router-dom";
import { MotionAnimations } from "../../../../animations/MotionAnimations";
import { Header } from "../../../../components";
import {
  AvailableProduct,
  CurrentState,
  Procedure,
  RequestOption,
} from "../../../../models";
import { RouteConstants } from "../../../../route/RouteConfig";
import { ProductService } from "../../../../services";
import { AuthService } from "../../../../services/auth.service";
import { Context, ReducerKeys } from "../../../../state";
import { AccordionSubItem } from "../../../shared/AccordionSubItem";
import { SampleForm } from "./SampleForm";

export const Step6 = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [showDialog, setShowDialog] = useState(false);
  const [sampleFormValid, setSampleFormValid] = useState(true);
  const history = useHistory();

  // onload
  useEffect(() => {
    if (state.requestOptions.length === 0) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const options = await trackPromise(ProductService.getRequestOptions());
    dispatch({ type: ReducerKeys.SET_REQUEST_OPTIONS, payload: options });
  };

  const selectionChanged = (items: RequestOptions[]) => {
    // Add to global store
    dispatch({
      type: ReducerKeys.SELECTED_REQUEST_OPTIONS,
      payload: items,
    });
  };

  const textChanged = (ev: any) => {
    // add input vaue to state
    dispatch({
      type: ReducerKeys.SET_REQUEST_DESCRIPTION,
      payload: ev.target.value,
    });
  };

  const sendRequest = async () => {
    const loggedIn = await AuthService.IsLoggedIn();
    setShowDialog(!loggedIn);

    await trackPromise(
      ProductService.ensureProcedure(new Procedure().fromState(state))
    );

    if (loggedIn) {
      try {
        // post data to cms
        let procedure = new Procedure().fromState(state);
        procedure.completed = true;
        await trackPromise(ProductService.ensureProcedure(procedure));

        // reset state
        dispatch({
          type: ReducerKeys.CLEAR_STEPS,
          payload: 6,
        });

        dispatch({
          type: ReducerKeys.SET_STEP,
          payload: 1,
        });

        history.push(RouteConstants.ThankYou);
      } catch (e: any) {
        e.forEach((error: string) => {
          if ((error = "You must be logged in to complete a procedure.")) {
            setShowDialog(true);
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Header step={6} />
      <main className="py-md-25 position-relative overflow-auto flex-grow bg-white">
        <motion.div
          className="container"
          initial={MotionAnimations.slideLeft.initial}
          animate={MotionAnimations.slideLeft.animate}
          exit={MotionAnimations.slideLeft.exit}
          transition={MotionAnimations.slideLeft.transition}
        >
          <h6 className="group-title">REQUEST</h6>
          <div className="accordion-checkbox-list">
            {state.requestOptions &&
              state.requestOptions.map((item: RequestOption, index: number) => {
                return (
                  <AccordionSubItem
                    key={index}
                    multiSelect={true}
                    item={item}
                    selectedItems={state.selectedRequestOptions}
                    selectionChanged={selectionChanged}
                  >
                    {(shouldOpen: Function) => {
                      if (
                        item.isSample &&
                        state.selectedRequestOptions.indexOf(item) > -1
                      ) {
                        return (
                          <div key={item.recordId}>
                            <SampleForm
                              isFormValid={(val: boolean) => {
                                setSampleFormValid(val);
                              }}
                            />
                          </div>
                        );
                      }
                      if (state.selectedRequestOptions.indexOf(item) === -1) {
                        return <div key={item.recordId} />;
                      }
                    }}
                  </AccordionSubItem>
                );
              })}
          </div>

          <h6 className="group-title">SELECTED PRODUCTS</h6>
          <div className="selected-list">
            {state.selectedAvailableProducts.map((item: AvailableProduct) => {
              return <h5 key={item.product}>{item.product}</h5>;
            })}
          </div>

          <h6 className="group-title">REMARK</h6>
          <div className="form-group" onChange={textChanged}>
            <textarea
              rows={3}
              value={state.requestDescription}
              className="form-control"
              placeholder="Leave us a remark"
            ></textarea>
          </div>
        </motion.div>
      </main>

      {showDialog && (
        <div className="modal active">
          <div className="modal-content d-flex text-center flex-column align-items-center">
            <button
              className="btn-close btn-reset"
              onClick={() => setShowDialog(false)}
            >
              <img src="/images/close.svg" className="d-block" alt="close" />
            </button>
            <h2>
              You need to be logged in or have an account in order to send us
              your request.
            </h2>
            <Link
              to={`${RouteConstants.Login}?redirectUrl=${window.location.pathname}`}
              className="btn bg-red btn-login mb-18 text-white btn-lg text-center"
            >
              Login
            </Link>
            <Link
              to={`${RouteConstants.Register}?redirectUrl=${window.location.pathname}`}
              className="btn-link text-blue"
            >
              Register now
            </Link>
          </div>
        </div>
      )}

      <motion.footer
        initial={MotionAnimations.fade.exit}
        animate={MotionAnimations.fade.enter}
        exit={MotionAnimations.fade.exit}
      >
        <footer className="footer text-center p-0 bg-white">
          <div className="container px-sm-0">
            <button
              style={{ fontSize: "15px" }}
              onClick={sendRequest}
              disabled={
                state.selectedRequestOptions?.length === 0 || !sampleFormValid
              }
              className="btn bg-blue text-white rounded-0 btn-lg text-center btn-block"
            >
              Request samples, pricing or meeting
            </button>
          </div>
        </footer>
      </motion.footer>
    </React.Fragment>
  );
};
