import { useEffect, useState } from "react";

export const EyeButton = (props: { changed: Function }) => {
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    props.changed(showPassword);
  }, [showPassword]);

  return (
    <button
      tabIndex={-1}
      className="form-icon"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPassword(!showPassword);
      }}
    >
      <img
        src={`/images/${showPassword ? "eye-closed.svg" : "eye.svg"}`}
        alt="icno"
      />
    </button>
  );
};
