import { ErrorMessage, Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import { CompanyLogo } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { ResetPasswordToken } from "../../models";
import { UserLogin } from "../../models/userLogin";
import { RouteConstants } from "../../route/RouteConfig";
import { UserService } from "../../services";
import { EyeButton } from "../shared/EyeButton";
import { GoBackButton } from "../shared/GoBackButton";

export const ResetPassword = () => {
  const { token } = useParams() as any;
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setRepeatShowPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as string[]);
  const [success, setSuccess] = useState(null as boolean | null);
  const errorRef = useRef(null);

  const initialValues = {
    email: "",
    password: "",
    repeatPassword: "",
  };

  useEffect(() => {
    if (validationErrors?.length > 0 || success !== null) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors, success]);

  const formValidation = (values: any, fullValidation: boolean) => {
    setValidationErrors([]);
    setSuccess(null);

    if (values.email && values.password && values.repeatPassword) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

    const errors: string[] = [];
    if (!fullValidation) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.push("Provide a valid e-mail.");
    }

    if (values.password !== values.repeatPassword) {
      errors.push("Passwords do not match.");
    }

    setValidationErrors(errors);
    return errors;
  };

  const resetPassword = async (data: UserLogin) => {
    try {
      const reset = new ResetPasswordToken({
        email: data.email,
        password: data.password,
        resetToken: token,
      });

      var result = await trackPromise(UserService.resetPassword(reset));
      setSuccess(result.resetPassword);
    } catch (e: any) {
      setFormValid(false);
      setValidationErrors(e);
    }
  };

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex py-20">
        <div className="container mt-auto">
          <div className="logo">
            <CompanyLogo />
          </div>
        </div>
      </header>

      <div className="container">
        <div className="mb-10 px-sm-15">
          <GoBackButton url={RouteConstants.Start} />
        </div>
      </div>

      <main className="py-25 px-sm-15 position-relative overflow-auto flex-grow d-flex bg-white">
        <div className="container">
          <div className="page-info mb-25 login-info ">
            <h1>Reset password</h1>
            <p>Please provide your username and a new password</p>
          </div>

          <Formik
            initialValues={initialValues}
            validate={(values) => formValidation(values, false)}
            onSubmit={(values, { setSubmitting }) => {
              const errors = formValidation(values, true);
              if (errors?.length === 0) {
                const obj = new UserLogin({
                  email: values.email,
                  password: values.password,
                });
                resetPassword(obj);
              }
            }}
            render={(formProps) => {
              return (
                <Form id="loginForm">
                  <div className="form-group">
                    <label>
                      E-MAIL<sup>*</sup>
                    </label>
                    <Field
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      placeholder="Your e-mail"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputPassword"
                        name="password"
                        placeholder="Your new password"
                        type={showPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) => setShowPassword(val)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      REPEAT PASSWORD<sup>*</sup>
                    </label>
                    <div className="position-relative">
                      <Field
                        className="form-control"
                        id="inputPassword"
                        name="repeatPassword"
                        placeholder="Repeat your new password"
                        type={showRepeatPassword ? "text" : "password"}
                      />
                      <EyeButton
                        changed={(val: boolean) => setRepeatShowPassword(val)}
                      />
                    </div>
                  </div>

                  <div ref={errorRef}>
                    {validationErrors?.length > 0 && (
                      <div className="pt-20">
                        {validationErrors.map((err: string) => {
                          return (
                            <React.Fragment key={err}>
                              <div className="text-danger">{err}</div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}

                    {success === true && (
                      <React.Fragment>
                        <div className="text-success pt-20">
                          Your password was reset successfully. You can now
                          login with your new credentials.
                        </div>
                      </React.Fragment>
                    )}

                    {success === false && (
                      <React.Fragment>
                        <br />
                        <div className="text-danger">
                          We could not process your request. Please try again by
                          requesting a new password reset.
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Form>
              );
            }}
          ></Formik>
        </div>
      </main>

      <footer className="footer px-sm-15 text-center bg-white">
        <div className="container">
          <button
            disabled={!formValid}
            form="loginForm"
            type="submit"
            className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
          >
            Reset
          </button>
        </div>
      </footer>
    </motion.div>
  );
};
