import { useContext, useState } from "react";
import { Dialog, MySelection } from "..";
import { AvailableProduct, CurrentState } from "../../models";
import { Context } from "../../state";
import { AccordionArrow } from "./AccordionArrow";

export const AccordionSubItem = (props: {
  item: any;
  multiSelect?: boolean;
  selectedItems: any[];
  selectionChanged: Function;
  children?: any;
  showScoreDialog?: boolean;
  showDetail?: Function;
  parentChanged?: Function;
  canDeselect?: boolean;
  style?: any;
}) => {
  const [state] = useContext(Context) as [CurrentState];
  const [isOpen, setIsOpen] = useState(false);
  const [parentIsOpen, setParentIsOpen] = useState(false);
  const [commentItem, setCommentItem] = useState(null as unknown as string);
  const [scoreItem, setScoreItem] = useState(
    null as unknown as AvailableProduct
  );

  const toggleSelection = (item: any) => {
    const items = Object.assign([], props.selectedItems);
    const stringifiedItems = Object.assign([], props.selectedItems).map(
      (i: any) => JSON.stringify(i)
    );

    const index = stringifiedItems.indexOf(JSON.stringify(item));
    if (props.multiSelect) {
      if (index === -1) {
        items.push(item);
      } else {
        items.splice(index, 1);
      }

      props.selectionChanged(items);
    } else {
      if (index === -1) {
        props.selectionChanged([item]);
      } else {
        if (props.canDeselect && props.multiSelect == false) {
          props.selectionChanged([item]);
        }
      }
    }
  };

  const parentClicked = (item: any) => {
    setParentIsOpen(!parentIsOpen);
    if (props.parentChanged) {
      props.parentChanged(item);
    }
  };

  const showCommentDialog = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCommentItem(props.item.comment);
  };

  const showScoreDialog = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setScoreItem(
      state.availableProducts.filter(
        (a) => a.recordId === props.item.recordId
      )[0]
    );
  };

  return (
    <div
      className={`accordion-checkbox ${isOpen === true ? "active" : ""} ${
        props.item.disabled ? "disabled" : ""
      }`}
    >
      <input
        type="checkbox"
        hidden
        checked={
          props.selectedItems.filter((s) => s?.recordId === props.item.recordId)
            .length > 0
        }
        onChange={() => {}}
      />
      <label
        className="accordion-checkbox-header d-flex align-items-center justify-content-space-between"
        onClick={() => {
          if (!props.item.isParent) {
            toggleSelection(props.item);
          }
          if (props.item.isParent) {
            parentClicked(props.item);
          }
          setIsOpen(true);
        }}
      >
        <div style={props.style}>
          <h5>
            {props.item.title}
            {props.item.name}
            {props.showScoreDialog && (
              <a onClick={(e) => showScoreDialog(e)}>
                <img
                  src="/images/info.svg"
                  className="sub-item-comment"
                  alt="info"
                />
              </a>
            )}
            {props.item.comment && (
              <a onClick={(e) => showCommentDialog(e)}>
                <img
                  className="sub-item-comment"
                  src="/images/info.svg"
                  alt="info"
                />
              </a>
            )}
            {props.item.comment && (
              <a onClick={(e) => showCommentDialog(e)}>
                <img
                  className="sub-item-comment"
                  src="/images/info.svg"
                  alt="info"
                />
              </a>
            )}
            {props.showDetail && (
              <a
                onClick={(e) =>
                  props.showDetail != null ? props.showDetail(props.item) : null
                }
              >
                <img
                  src="/images/info.svg"
                  className="sub-item-comment"
                  alt="info"
                />
              </a>
            )}
          </h5>
          {props.item.category && <p>{props.item.category?.name}</p>}
        </div>

        {!props.item.isParent && (
          <span className="accordion-checkbox-circle">
            {props.multiSelect && (
              <img src="/images/checked.svg" alt="checked" />
            )}
          </span>
        )}

        {props.item.isParent && (
          <div className={`accordion-item ${parentIsOpen ? "active" : ""}`}>
            <span className="parent-arrow accordion-arrow">
              <AccordionArrow />
            </span>
          </div>
        )}
      </label>

      {props.children !== undefined &&
        props.children((shouldOpen: boolean) => {
          setIsOpen(shouldOpen);
        })}

      {commentItem && (
        <Dialog
          onClose={() => {
            setCommentItem(null as any);
          }}
        >
          {() => {
            return (
              <div className="w-100">
                {commentItem && (
                  <div
                    className="mb-8"
                    dangerouslySetInnerHTML={{
                      __html: `${commentItem}`,
                    }}
                  />
                )}
              </div>
            );
          }}
        </Dialog>
      )}

      {scoreItem && (
        <Dialog
          onClose={() => {
            setScoreItem(null as unknown as AvailableProduct);
          }}
        >
          {() => {
            return <MySelection availableProduct={scoreItem} />;
          }}
        </Dialog>
      )}
    </div>
  );
};
