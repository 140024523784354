export enum ReducerKeys {
  SET_STEPS,
  SET_INFORMATION_TEXT,
  SET_STEP,
  SET_LAMINATION_MATERIALS,
  SELECTED_LAMINATION_MATERIAL,
  SET_SURFACE_ENERGY_MATERIALS,
  SELECTED_SURFACE_ENERGY_MATERIAL,
  SET_APPLICATION_REQUIREMENTS,
  SELECTED_APPLICATION_REQUIREMENTS,
  SET_LINERTYPES,
  SET_CONSTRUCTIONTYPES,
  SELECTED_CONSTRUCTIONTYPES,
  SET_AVAILABLE_PRODUCTS,
  SELECTED_AVAILABLE_PRODUCTS,
  SET_REQUEST_OPTIONS,
  SELECTED_REQUEST_OPTIONS,
  CLEAR_STEPS,
  SET_PROCEDURE_ID,
  SET_REQUEST_DESCRIPTION,
  SET_SELECTED_PARENT_CONSTRUCTIONTYPE,
  SET_SELECTED_MAX_TEMPERATURE,
  SET_SHIPPINGDETAILS,
}
