import { motion } from "framer-motion";
import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { trackPromise } from "react-promise-tracker";
import { AddAdminDialog, GoBackButton, SearchBox, UserIcon } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { CurrentState, UserDetails } from "../../models";
import { UserService } from "../../services";
import { Context } from "../../state";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { toast } from "react-toastify";

export const Admins = () => {
  const [loaded, setLoaded] = useState(false);
  const [state] = useContext(Context) as [CurrentState];
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [errors, setErrors] = useState([] as string[]);
  const [users, setUsers] = useState([] as UserDetails[]);
  const errorRef = useRef(null);
  const [filterText, setFilterText] = React.useState("");
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [selectedUserMail, setSelectedUserMail] = useState(
    null as unknown as string
  );

  // onload
  useEffect(() => {
    if (state.steps.length > 0) {
      fetchAdmins();
    }
  }, [state.steps]);

  useEffect(() => {
    if (errors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [errors]);

  const fetchAdmins = async () => {
    try {
      setErrors([]);
      const users = await trackPromise(UserService.getAdmins());
      setUsers(users);
      setLoaded(true);
    } catch (e: any) {
      setErrors(e);
    }
  };

  const removeAdmin = async (email: string) => {
    try {
      setErrors([]);
      await trackPromise(UserService.deleteAdmin(email));
      toast.success("Admin removed!");
      fetchAdmins();
    } catch (e: any) {
      setErrors(e);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        name: "Firstname",
        selector: "firstName",
        sortable: true,
      },
      {
        name: "Lastname",
        selector: "lastName",
        sortable: true,
      },
      {
        name: "email",
        selector: "email",
        sortable: true,
      },
      {
        name: "",
        selector: "edit",
        sortable: false,
        cell: (row: any) => (
          <a
            onClick={() => {
              setShowConfirmDelete(true);
              setSelectedUserMail(row.email);
            }}
          >
            <img src="/images/delete.svg" alt="delete" />
          </a>
        ),
        button: true,
      },
    ],
    []
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <React.Fragment>
        <div>
          <a onClick={() => setShowAddDialog(true)}>
            <img src="/images/add.svg" alt="add" />
          </a>
        </div>
        <div>
          <SearchBox
            placeHolder="Search by user"
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </div>
      </React.Fragment>
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = users.filter(
    (item) =>
      (item.firstName &&
        `${item.firstName}`.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.lastName &&
        `${item.lastName}`.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        `${item.email}`.toLowerCase().includes(filterText.toLowerCase()))
  );

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton />
            <UserIcon />
          </div>

          <div className="page-info px-0">
            <div className="page-info-step mb-8 d-flex align-items-center">
              <h1>Admin management</h1>
            </div>
          </div>
        </div>
      </header>

      <main className="py-md-25 py-20 position-relative overflow-auto flex-grow bg-white">
        {loaded && (
          <div className="container admin-container">
            <DataTable
              data={filteredItems}
              columns={columns}
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
            />

            {showConfirmDelete && selectedUserMail && (
              <ConfirmDeleteDialog
                onClose={() => setShowConfirmDelete(false)}
                onConfirm={() => {
                  setShowConfirmDelete(false);
                  removeAdmin(selectedUserMail);
                }}
                email={selectedUserMail}
              />
            )}

            {showAddDialog && (
              <AddAdminDialog
                onClose={() => setShowAddDialog(false)}
                onConfirm={() => {
                  setShowAddDialog(false);
                  fetchAdmins();
                }}
              />
            )}

            {errors?.length > 0 && (
              <div ref={errorRef} className="pt-20">
                {errors.map((err: string) => {
                  return (
                    <React.Fragment key={err}>
                      <div className="text-danger">{err}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </main>
    </motion.div>
  );
};
