export const Spinner = () => {
  return (
    <div className="modal active">
      <div className="spinner">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
