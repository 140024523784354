import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";

export const FullOptionPieChart = (props: {
  data: any[];
  onClick: Function;
}) => {
  const [hovered, setHovered] = useState<number | undefined>(undefined);
  const data = props.data.map((entry: Data, i: number) => {
    if (hovered === i) {
      return {
        ...entry,
        color: "grey",
      };
    }
    return entry;
  });

  const lineWidth = 60;

  return (
    <React.Fragment>
      <PieChart
        style={{
          fontSize: "8px",
          maxHeight: "350px",
        }}
        data={data as any}
        radius={(PieChart as any).defaultProps.radius - 6}
        lineWidth={60}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        segmentsShift={1}
        animate
        label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
        labelPosition={100 - lineWidth / 2}
        labelStyle={{
          fill: "#fff",
          opacity: 0.75,
          pointerEvents: "none",
        }}
        onClick={(_, index) => {
          props.onClick(index);
        }}
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(undefined);
        }}
      />

      <div className="d-flex justify-content-center">
        <div>
          {data &&
            data.map((d: any, index: number) => {
              return (
                <div key={d.title} onClick={() => props.onClick(index)}>
                  <span
                    className="aci-dot"
                    style={{
                      backgroundColor: d.color,
                      display: "inline-block",
                    }}
                  ></span>
                  <span className="aci-name">
                    {d.title}: {d.value}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};
