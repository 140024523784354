import React, { useContext, useEffect, useState } from "react";
import { AccordionArrow, Dialog, LinerAttributeBody } from "../../components";
import {
  AvailableProduct,
  CurrentState,
  LinerAttribute,
  LinerType,
} from "../../models";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { trackPromise } from "react-promise-tracker";
import { ProductService } from "../../services";
import { Context, ReducerKeys } from "../../state";

export const ProductResultBody = (props: {
  item: AvailableProduct;
  onOpen?: Function;
}) => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [currentItem, setCurrentItem] = useState(null as unknown as LinerType);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const linerTypes = await trackPromise(ProductService.getLinerTypes());
    dispatch({
      type: ReducerKeys.SET_LINERTYPES,
      payload: linerTypes,
    });
  };

  const openDatasheet = async () => {
    if (Capacitor.isNativePlatform() === true) {
      Browser.open({ url: props.item.datasheet });
    } else {
      window.open(props.item.datasheet, "_blank");
    }
  };

  const openDialog = (linerType: LinerType) => {
    const linerAttributes = state.linerTypes.find(
      (x) => x.recordId == linerType.recordId
    )?.linerAttributes as LinerAttribute[];
    linerType.linerAttributes = linerAttributes;

    setCurrentItem(linerType);
  };

  return (
    <React.Fragment>
      <div className="accordion-checkbox-body">
        <div className="accordion-checkbox-item d-flex align-items-center">
          <div className="accordion-result">
            <div className="d-flex mb-8 align-items-center">
              <div className="accordion-result-title">CONSTRUCTION</div>
              <div>{props.item.construction}</div>
            </div>
            <div className="d-flex mb-8">
              <div className="accordion-result-title">LINER TYPES</div>
              <div>
                {props.item.linerTypes &&
                  props.item.linerTypes.map(
                    (type: LinerType, index: number) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            paddingBottom:
                              index == props.item.linerTypes.length - 1
                                ? "0px"
                                : "8px",
                          }}
                        >
                          <div>{type.name}</div>
                          <a
                            style={{ marginLeft: "10px" }}
                            onClick={() => openDialog(type)}
                          >
                            <img src="/images/info.svg" alt="info" />
                          </a>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div className="d-flex mb-8 align-items-center">
              <div className="accordion-result-title">LINER OPTIONS</div>
              <div>{props.item.linerOptions}</div>
            </div>
            <div className="d-flex mb-8 align-items-center">
              <div className="accordion-result-title">THICKNESS</div>
              <div>{props.item.thickness}</div>
            </div>
          </div>
        </div>
      </div>
      {props.onOpen && (
        <div className="accordion-btns d-flex align-items-center accordion-btns-normal">
          <button
            onClick={(event) => (props.onOpen as Function)(true)}
            type="button"
            className="btn-show btn-reset"
            style={{ minWidth: "135px", textAlign: "left" }}
          >
            Show details
            <AccordionArrow />
          </button>

          <button
            onClick={(event) => (props.onOpen as Function)(false)}
            type="button"
            className="btn-hide btn-reset"
            style={{ minWidth: "135px", textAlign: "left" }}
          >
            Hide details
            <AccordionArrow />
          </button>

          {props.item.datasheet && (
            <a
              onClick={openDatasheet}
              className="text-blue btn-link"
              style={{ marginLeft: "10px" }}
            >
              Download data sheet
            </a>
          )}
        </div>
      )}

      {currentItem && (
        <Dialog
          onClose={() => {
            setCurrentItem(null as any);
          }}
        >
          {() => {
            return (
              <div style={{ width: "100%" }}>
                <h2>{currentItem.name}</h2>
                <LinerAttributeBody items={currentItem.linerAttributes} />
              </div>
            );
          }}
        </Dialog>
      )}
    </React.Fragment>
  );
};
