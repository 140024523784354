import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CompanyLogo, Dialog, GoBackButton, UserIcon } from "..";
import { CurrentState } from "../../models";
import { RouteConstants } from "../../route/RouteConfig";
import { Context, ReducerKeys } from "../../state";
import { Preferences } from "@capacitor/preferences";

export const About = () => {
  const [state, dispatch] = useContext(Context) as [CurrentState, Function];
  const [showDialog, setShowDialog] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const history = useHistory();

  dispatch({
    type: ReducerKeys.CLEAR_STEPS,
    payload: 6,
  });

  const goToSteps = async () => {
    await Preferences.set({
      key: "hideAbout",
      value: dontShowAgain?.toString(),
    });

    history.push(RouteConstants.Steps);
  };

  return (
    <React.Fragment>
      <header className="header header-auto pb-2">
        <div className="container">
          <div className="header-control d-flex align-items-end">
            <CompanyLogo />
            <UserIcon />
          </div>
        </div>
      </header>

      {state.informationText && (
        <React.Fragment>
          <main className="px-sm-15 text-gray-3 position-relative overflow-auto flex-grow d-md-flex align-items-center bg-white">
            <div className="container">
              <div className="page-info mb-25 login-info">
                <GoBackButton />
              </div>
              <h1 className="page-title text-blue">ABOUT</h1>

              <h2
                dangerouslySetInnerHTML={{
                  __html: `${state.informationText?.aboutTitle}`,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: `${state.informationText?.aboutDescription}`,
                }}
              />
              <p>
                <br />
                <input
                  id="check"
                  type="checkbox"
                  checked={dontShowAgain}
                  onChange={() => setDontShowAgain(!dontShowAgain)}
                />
                <label htmlFor="check"> Don't show this again.</label>
              </p>
            </div>
          </main>
          <footer className="footer px-sm-15 text-center bg-white">
            <div className="container">
              <button
                style={{ fontSize: "15px" }}
                onClick={goToSteps}
                className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
              >
                Start now
              </button>
            </div>
          </footer>
          {showDialog && (
            <Dialog onClose={() => setShowDialog(false)}>
              {() => {
                return (
                  <div className="w-100">
                    <div
                      className="pb-50"
                      dangerouslySetInnerHTML={{
                        __html: `${state.informationText?.startNowDialogDescription}`,
                      }}
                    />
                    <Link
                      to={RouteConstants.Steps}
                      className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
                    >
                      Start now
                    </Link>
                  </div>
                );
              }}
            </Dialog>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
