import { motion } from "framer-motion";
import React, { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { trackPromise } from "react-promise-tracker";
import { GoBackButton, UserIcon } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { AmountPerUser, CurrentState } from "../../models";
import { Stat } from "../../models/stat";
import { StatsService } from "../../services/stats.service";
import { Context } from "../../state";
import { FullOptionPieChart } from "../shared/FullOptionPieChart";
import { SearchBox } from "../shared/SearchBox";

export const Stats = () => {
  const [state] = useContext(Context) as [CurrentState];
  const [stats, setStats] = useState([] as Stat[]);
  const [statsPerUser, setStatsPerUser] = useState([] as AmountPerUser[]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [totalPieData, setTotalPieData] = useState([] as any[]);
  const [unfinishedPieData, setUnfinishedPieData] = useState([] as any[]);
  const [filterText, setFilterText] = React.useState("");
  const [tableTitle, setTableTitle] = React.useState("");
  const [errors, setErrors] = useState([] as string[]);
  const errorRef = useRef(null);

  // onload
  useEffect(() => {
    if (state.steps.length > 0) {
      fetchStats();
    }
  }, [state.steps]);

  useEffect(() => {
    if (errors?.length > 0) {
      (errorRef.current as any)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [errors]);

  const fetchStats = async () => {
    try {
      setErrors([]);
      const stats = await trackPromise(StatsService.GetStats());
      setStats(stats);

      const totalPieData = stats
        .filter((s) => s.type !== "TOTAL_PROCEDURES")
        .map((s, index) => {
          return {
            key: s.type,
            title: s.description,
            value: s.totalAmount,
            color: state.steps[index].statsKleur,
          };
        });
      setTotalPieData(totalPieData as any);

      const data: any = stats
        .filter((s) => s.amountPerStep != null)
        .map((s) => {
          return s.amountPerStep.map((a, index) => {
            return {
              key: a.key,
              title: `Step ${a.key}: ${state.steps[index].title}`,
              value: a.value,
              color: state.steps[index].statsKleur,
            };
          });
        });

      setUnfinishedPieData([].concat(...data));
    } catch (e: any) {
      setErrors(e);
    }
  };

  const fetchStatsPerUser = async (completed: boolean, step?: number) => {
    try {
      setErrors([]);
      const stats = await trackPromise(
        StatsService.GetStatsPerUser(completed, step)
      );
      setStatsPerUser(stats);

      if (step) {
        setTableTitle(`Step ${step}: ${state.steps[step - 1].title}`);
      } else {
        setTableTitle(
          completed === true ? "Finished requests" : "Unfinished requests"
        );
      }
    } catch (e: any) {
      setErrors(e);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        name: "User",
        selector: "key",
        sortable: true,
      },
      {
        name: "Amount",
        selector: "value",
        sortable: true,
      },
    ],
    []
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <React.Fragment>
        <div>
          <SearchBox
            placeHolder="Search by user"
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </div>
      </React.Fragment>
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = statsPerUser.filter(
    (item) =>
      item.key && `${item.key}`.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header header-auto header-gray">
        <div className="container">
          <div className="header-control d-flex align-items-center">
            <GoBackButton />
            <UserIcon />
          </div>

          <div className="page-info px-0">
            <div className="page-info-step mb-8 d-flex align-items-center">
              <h1>Stats</h1>
            </div>
          </div>
        </div>
      </header>

      <main className="py-md-25 py-20 position-relative overflow-auto flex-grow bg-white">
        <div className="container">
          {statsPerUser.length === 0 &&
            state.steps.length > 0 &&
            stats.length > 0 && (
              <React.Fragment>
                <div className="py-20 pb-50">
                  <div className="d-flex justify-content-center page-info">
                    <h1>Total requests</h1>
                  </div>

                  <FullOptionPieChart
                    data={totalPieData}
                    onClick={(index: number) => {
                      if (totalPieData[index].key === "FINISHED_PROCEDURES") {
                        fetchStatsPerUser(true);
                      } else {
                        fetchStatsPerUser(false);
                      }
                    }}
                  />
                </div>
                <div className="py-20">
                  <div className="d-flex justify-content-center page-info">
                    <h1>Unfinished requests</h1>
                  </div>

                  <FullOptionPieChart
                    data={unfinishedPieData}
                    onClick={(index: number) => {
                      fetchStatsPerUser(false, index + 1);
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          {statsPerUser.length > 0 && (
            <React.Fragment>
              <div className="d-flex justify-content-center align-items-center page-info">
                <a
                  onClick={() => {
                    setStatsPerUser([]);
                    setTableTitle("");
                  }}
                  className="btn-back"
                >
                  <img
                    style={{ paddingRight: "8px", paddingBottom: "8px" }}
                    src="/images/back-arrow.svg"
                  />
                </a>
                <h2>{tableTitle}</h2>
              </div>
              <DataTable
                data={filteredItems}
                columns={columns}
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
              />
            </React.Fragment>
          )}
          {errors?.length > 0 && (
            <div ref={errorRef} className="pt-20">
              {errors.map((err: string) => {
                return (
                  <React.Fragment key={err}>
                    <div className="text-danger">{err}</div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </main>
    </motion.div>
  );
};
