import React from "react";
import { ConstructionType } from "../../models";

export const ConstructionTypeBody = (props: { item: ConstructionType }) => {
  return (
    <React.Fragment>
      <div className="accordion-checkbox-header no-padding w-100">
        <h4 style={{ marginTop: "0" }}>{props.item.title}</h4>
        {props.item.description && (
          <p
            className="pb-2"
            dangerouslySetInnerHTML={{
              __html: props.item.description,
            }}
          ></p>
        )}
        {props.item.visual && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              width="320px"
              style={{ maxWidth: "100%" }}
              src={props.item.visual}
              alt={props.item.title}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
