import { motion } from "framer-motion";
import React from "react";
import { CompanyLogo } from "..";
import { MotionAnimations } from "../../animations/MotionAnimations";
import { Capacitor } from "@capacitor/core";

export const UpdateApp = () => {
  return (
    <motion.div
      initial={MotionAnimations.fade.exit}
      animate={MotionAnimations.fade.enter}
      exit={MotionAnimations.fade.exit}
      className="d-flex flex-column position-relative w-100 vh-100"
    >
      <header className="header d-flex">
        <div className="container">
          <div className="logo d-flex justify-content-end">
            <CompanyLogo />
          </div>
        </div>
      </header>

      <img src="images/home-image.png" className="home-image" alt="" />

      <React.Fragment>
        <main className="home-content position-relative overflow-auto text-white flex-grow bg-blue">
          <div className="container">
            <h1>Update your app</h1>
            <p className="mb-18 ">
              In order to continue using the Core Series™ Portfolio Product
              Selection Tool you need to have the latest version installed.
            </p>
          </div>
        </main>
        {!Capacitor.isNativePlatform() && (
          <footer className="footer px-sm-15 text-center bg-white">
            <div className="container">
              <a
                className="btn bg-red mb-18 text-white btn-xl text-center btn-block"
                href={`/?v=${new Date().getTime()}`}
              >
                Update
              </a>
            </div>
          </footer>
        )}
      </React.Fragment>
    </motion.div>
  );
};
