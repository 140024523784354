export const Dialog = (props: { children: Function; onClose: any }) => {
  const close = (ev: any) => {
    const content = ev.target.closest(".modal-content");
    if (content === null) {
      // clicked outside
      props.onClose();
    }
  };
  return (
    <div className="modal active" onClick={(ev) => close(ev)}>
      <div className="modal-content d-flex flex-column align-items-center">
        <button
          className="btn-close btn-reset"
          onClick={(ev) => props.onClose()}
        >
          <img src="/images/close.svg" className="d-block" alt="close" />
        </button>

        {props.children()}
      </div>
    </div>
  );
};
