import { useHistory } from "react-router-dom";

export const GoBackButton = (props: { url?: string }) => {
  const history = useHistory();

  const goBack = () => {
    if (props.url) {
      history.push(props.url);
    } else {
      history.goBack();
    }
  };

  return (
    <a onClick={goBack} className="btn-back">
      <img src="/images/back-arrow.svg" alt="" />
    </a>
  );
};
